import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import RUtraning1 from "../../assets/traning/RU_traning-1.jpg";
import RUtraning2 from "../../assets/traning/RU_traning-2.jpg";
import RUtraning3 from "../../assets/traning/RU_traning-3.jpg";
import RUtraning4 from "../../assets/traning/RU_traning-4.jpg";
import RUtraning5 from "../../assets/traning/RU_traning-5.jpg";
import ARtraning1 from "../../assets/traning/am_traning-1.png";
import ARtraning2 from "../../assets/traning/am_traning-2.png";
import ARtraning3 from "../../assets/traning/am_traning-3.png";
import ARtraning4 from "../../assets/traning/am_traning-4.png";
import ARtraning5 from "../../assets/traning/am_traning-5.png";
import EStraning1 from "../../assets/traning/la_traning-1.png";
import EStraning2 from "../../assets/traning/la_traning-2.png";
import EStraning3 from "../../assets/traning/la_traning-3.png";
import EStraning4 from "../../assets/traning/la_traning-4.png";
import EStraning5 from "../../assets/traning/la_traning-5.png";
import ZHtraning1 from "../../assets/traning/zh_traning-1.png";
import ZHtraning2 from "../../assets/traning/zh_traning-2.png";
import ZHtraning3 from "../../assets/traning/zh_traning-3.png";
import ZHtraning4 from "../../assets/traning/zh_traning-4.png";
import ZHtraning5 from "../../assets/traning/zh_traning-5.png";
import iphoneBorder from "../../assets/iphoneBorder.png";
import {
  SliderWrapper,
  SliderIphone,
  SliderIphoneWrapper,
  SliderContainer,
  SliderImg,
  SliderTitle,
  SliderText,
  Controls,
  PrevSlideButton,
  NextSlideButton,
  Dots,
  Dot,
} from "./style";
import { ArrowImg } from "../../style";
import arrow from "../../assets/arrowDark.svg";
import i18n from "../../i18n";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const slides = {
  ru: [RUtraning1, RUtraning2, RUtraning3, RUtraning4, RUtraning5],
  zh: [ZHtraning1, ZHtraning2, ZHtraning3, ZHtraning4, ZHtraning5],
  es: [EStraning1, EStraning2, EStraning3, EStraning4, EStraning5],
  ar: [ARtraning1, ARtraning2, ARtraning3, ARtraning4, ARtraning5],
};

export const TrainingSlider = ({ isRtl }) => {
  const { t } = useTranslation("landing");
  const [currentSlider, setCurrentSlider] = useState(0);
  const language = i18n.language.split("-")[0] || "ru";

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_, newIndex) => setCurrentSlider(newIndex),
  };

  const sliderRef = useRef();
  const textSliderRef = useRef();

  const sliderImgs = slides[language] || slides.ru;

  useEffect(() => {
    sliderRef.current.slickGoTo(currentSlider);
    textSliderRef.current.slickGoTo(currentSlider);
  }, [currentSlider]);

  const prevSlider = () => {
    sliderRef.current.slickPrev();
  };

  const nextSlider = () => {
    sliderRef.current.slickNext();
  };

  return (
    <>
      <SliderWrapper>
        <Slider ref={sliderRef} {...settings}>
          {sliderImgs.map((img, key) => (
            <SliderContainer key={`keySlider${img}${key}`}>
              <SliderImg src={img} />
            </SliderContainer>
          ))}
        </Slider>
        <SliderIphoneWrapper>
          <SliderIphone isRtl={isRtl} src={iphoneBorder} />
        </SliderIphoneWrapper>
      </SliderWrapper>
      <div style={{ width: "100%" }}>
        <Slider ref={textSliderRef} {...settings}>
          {sliderImgs.map((_, key) => (
            <div key={`sliderImgsText${key}`}>
              <SliderTitle>{t(`trainingSlides.${key}.title`)}</SliderTitle>
              <SliderText>{t(`trainingSlides.${key}.text`)}</SliderText>
            </div>
          ))}
        </Slider>
      </div>
      <Controls>
        <PrevSlideButton
          style={{ visibility: currentSlider === 0 ? "hidden" : "visible" }}
          isRtl={isRtl}
          onClick={() => prevSlider()}
        >
          <ArrowImg src={arrow} />
        </PrevSlideButton>
        <Dots>
          {sliderImgs.map((_, key) => (
            <Dot
              key={`sliderImgsDot${key}`}
              isActive={currentSlider === key}
              onClick={() => setCurrentSlider(key)}
            />
          ))}
        </Dots>
        <NextSlideButton
          style={{
            visibility:
              currentSlider === sliderImgs.length - 1 ? "hidden" : "visible",
          }}
          isRtl={!isRtl}
          onClick={() => nextSlider()}
        >
          <ArrowImg src={arrow} />
        </NextSlideButton>
      </Controls>
    </>
  );
};
