import { BenefitsList, Benefit, BenefitIcon, BenefitText } from "./style";
import riseUp from "../../assets/riseUp.svg";
import brain from "../../assets/brain.svg";
import speak from "../../assets/speak.svg";
import ghost from "../../assets/ghost.svg";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";

export const Benefits = ({ isRtl }) => {
  const { t } = useTranslation("landing");
  const listOfBenefits = [
    { iconSrc: riseUp, text: "riseUp" },
    {
      iconSrc: brain,
      text: "notForget",
    },
    {
      iconSrc: speak,
      text: "speak",
    },
    {
      iconSrc: ghost,
      text: "notAfraid",
    },
  ];

  return (
    <BenefitsList>
      {listOfBenefits.map((benefit) => (
        <Benefit key={benefit.text}>
          <BenefitIcon src={benefit.iconSrc} />
          <BenefitText isRtl={isRtl}>
            <Trans t={t} i18nKey={benefit.text} />
          </BenefitText>
        </Benefit>
      ))}
    </BenefitsList>
  );
};
