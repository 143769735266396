import { CopyRightStyled } from "./style";
import { useTranslation } from "react-i18next";

export const CopyRight = () => {
  const { t } = useTranslation("landing");

  return (
    <CopyRightStyled>
      {t("copyright", { year: new Date().getFullYear() })}
    </CopyRightStyled>
  );
};
