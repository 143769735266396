import amplitude from "amplitude-js";

const createPaymentIntent = (email, locale, productId, priceId) => {
  let adSource = localStorage.getItem("pid");
  const afAdset = localStorage.getItem("af_adset");
  const afAd = localStorage.getItem("af_ad");
  if (!adSource) adSource = "Blog";
  const appsFlyerCUID = localStorage.getItem("appsFlyerCUID");
  return fetch(
    `${process.env.REACT_APP_PUBLIC_URL}/app-portal/landing/stripe/create-payment-intent`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        locale,
        productId,
        priceId,
        how: "card",
        amplitudeId: amplitude.getSessionId(),
        appsFlyerCUID: appsFlyerCUID,
        deviceId: amplitude.getInstance().options.deviceId,
        adSource,
        afAdset,
        afAd,
      }),
    }
  ).then((response) => response.json());
};

export default createPaymentIntent;
