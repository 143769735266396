import { TermsAndPrivacy, TermsAndPrivacyText } from "./style";
import { Amplitude } from "@amplitude/react-amplitude";
import { rtlDetect } from "../../libs/isRtl";
import { useTranslation } from "react-i18next";

const termsLinks = {
  es: {
    termsOfUse:
      "//appspeechen.s3.us-west-002.backblazeb2.com/iOS/LandingOffer/condiciones_bright.pdf",
    privacy:
      "//appspeechen.s3.us-west-002.backblazeb2.com/iOS/LandingOffer/privacidad_bright.pdf",
    moneyBack:
      "//appspeechen.s3.us-west-002.backblazeb2.com/iOS/LandingOffer/devolucion_bright.pdf",
  },
  en: {
    termsOfUse:
      "//appspeechen.s3.us-west-002.backblazeb2.com/iOS/LandingOffer/terms_bright.pdf",
    privacy:
      "//appspeechen.s3.us-west-002.backblazeb2.com/iOS/LandingOffer/privacy_bright.pdf",
    moneyBack:
      "//appspeechen.s3.us-west-002.backblazeb2.com/iOS/LandingOffer/moneyback_bright.pdf",
  },
};

const customPrivacy = Object.keys(termsLinks);

export const Terms = ({ currentLanguage }) => {
  const { t } = useTranslation("landing");

  const isCustomePrivacy = customPrivacy.includes(currentLanguage);

  const isRtl = rtlDetect();

  return (
    <TermsAndPrivacy
      isRus={["ru", "es", "en"].includes(currentLanguage)}
      isRtl={isRtl}
    >
      <Amplitude>
        {({ logEvent }) => (
          <TermsAndPrivacyText
            onClick={() => {
              logEvent("landing_tap_terms_of_use");
            }}
            target={isCustomePrivacy ? "_blank" : ""}
            to={
              isCustomePrivacy
                ? termsLinks[currentLanguage].termsOfUse
                : `/terms-of-use/${currentLanguage}`
            }
          >
            {t("Terms of use")}
          </TermsAndPrivacyText>
        )}
      </Amplitude>
      <Amplitude>
        {({ logEvent }) => (
          <TermsAndPrivacyText
            onClick={() => {
              logEvent("landing_tap_privacy_policy");
            }}
            target={isCustomePrivacy ? "_blank" : ""}
            to={
              isCustomePrivacy
                ? termsLinks[currentLanguage].privacy
                : `/privacy-policy/${currentLanguage}`
            }
          >
            {t("Privacy policy")}
          </TermsAndPrivacyText>
        )}
      </Amplitude>
      {isCustomePrivacy && (
        <Amplitude>
          {({ logEvent }) => (
            <TermsAndPrivacyText
              onClick={() => {
                logEvent("landing_tap_money_back_policy");
              }}
              target="_blank"
              to={termsLinks[currentLanguage].moneyBack}
            >
              {t("moneyBackPolicy")}
            </TermsAndPrivacyText>
          )}
        </Amplitude>
      )}
    </TermsAndPrivacy>
  );
};
