import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import { Container } from "./components/Container";
import { Title } from "./components/Title";
import { ReactComponent as Logo } from "./assets/logo.svg";
import { Terms } from "./components/Terms/Terms";
import { CopyRight } from "./components/CopyRight/CopyRight";
import {
  Description,
  DescriptionBold,
  HaveQuestion,
  SocialButtons,
  SocialButton,
  SocialButtonText,
  AppStoreContainer,
} from "./style";
import { Button } from "./components/Button";
import { AboutBright } from "./components/AboutBright";
import { ReactComponent as EmailYellow } from "./assets/mailYellow.svg";
import { ReactComponent as FacebookYellow } from "./assets/facebookYellow.svg";
import { ReactComponent as AppStoreEN } from "./assets/en_app-store_white.svg";
import { isFacebookApp, Rate } from "./components/Rate";
import { useLayoutEffect, useEffect } from "react";
import "@lottiefiles/lottie-player";
import { rtlDetect } from "./libs/isRtl";
import { dic, LOCAL_STORAGE_KEY_LANDING_SHOW_TIME } from "./App";
import { Amplitude } from "@amplitude/react-amplitude";
import amplitude from "amplitude-js";
import { useLocation } from "react-router";

const getElmPosition = (href) =>
  document.querySelector(href).getBoundingClientRect().top;

const addMinutes = (date, minutes) =>
  new Date(date.getTime() + minutes * 60000).getTime();

function LandingEnglishVersion() {
  const { t } = useTranslation("landing");
  const location = useLocation();
  const currentLanguage = "en";

  const isRtl = rtlDetect();

  useLayoutEffect(() => {
    document.title = t("titlePage");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchFTPRedirect = async () => {
      try {
        const deviceId = localStorage.getItem("deviceId");
        const { redirectUrl } = await fetch(
          `${process.env.REACT_APP_PUBLIC_URL}/app-portal/landing/redirectstart/${currentLanguage}/${deviceId}`,
          {
            method: "post",
            headers: {
              "Content-type": "application/json",
            },
          }
        ).then((response) => {
          return response.json();
        });

        localStorage.setItem("BrightRedirectUrl", redirectUrl);
      } catch (error) {
        console.error(error);
      }
    };

    const landingShowTime = localStorage.getItem(
      LOCAL_STORAGE_KEY_LANDING_SHOW_TIME
    );
    if (!landingShowTime) {
      amplitude.getInstance().logEvent("landing_show");
      localStorage.setItem(
        LOCAL_STORAGE_KEY_LANDING_SHOW_TIME,
        Date.now().toString()
      );
    } else {
      const landingShowTimePlus1Hour = addMinutes(
        new Date(Number(landingShowTime)),
        60
      );
      if (Date.now() > landingShowTimePlus1Hour) {
        amplitude.getInstance().logEvent("landing_show");
        localStorage.setItem(
          LOCAL_STORAGE_KEY_LANDING_SHOW_TIME,
          Date.now().toString()
        );
      }
    }
    localStorage.setItem("waypoint", 0);
    localStorage.setItem("waypoint_time", Date.now());
    if (isFacebookApp()) {
      fetchFTPRedirect();
    }
  }, [currentLanguage]);

  const analiticsParamsFromUrl = [
    { name: "pid", ls: "pid" },
    { name: "utm_content", ls: "af_adset" },
    { name: "utm_term", ls: "af_ad" },
  ];

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    analiticsParamsFromUrl.map((elm) =>
      urlParams.has(elm.name)
        ? localStorage.setItem(elm.ls, urlParams.get(elm.name))
        : localStorage.removeItem(elm.ls)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = (e, href) => {
    e.preventDefault();
    const elmPosition = getElmPosition(href);
    window.scrollBy({ top: elmPosition, left: 0, behavior: "smooth" });
  };

  return (
    <div id="firstSection">
      <Container
        align="center"
        styles={{
          padding: "45px 0",
          background:
            "linear-gradient(197.63deg, #784CF5 15.21%, #4C7BF5 84.84%)",
          borderBottomRightRadius: !isRtl && 56,
          borderBottomLeftRadius: isRtl && 56,
        }}
      >
        <Logo />
        <Title
          dir="auto"
          styles={{
            textTransform: "uppercase",
            color: "#fff",
            marginTop: 24,
            textAlign: "center",
            whiteSpace: "pre-line",
          }}
        >
          {t("smartApplication")}
        </Title>
        <Description>
          {t("remember")}
          <br />
          <DescriptionBold>{t("withOutProblem")}</DescriptionBold>
        </Description>
        <Amplitude>
          {({ logEvent }) => (
            <Button
              onClick={(e) => {
                logEvent("landing_tap_go_to_purchase");
                onClick(e, "#menuStart", 8);
              }}
              text={t("getAccess")}
            />
          )}
        </Amplitude>
        <div style={{ padding: "20px" }}></div>
        <AboutBright />
      </Container>
      <div id="menuStart"></div>
      <Container
        align="left"
        styles={{
          padding: "57px 0 152px",
          background: "#F5F1EB",
          overflow: "visible",
        }}
      >
        <Title
          styles={{
            color: "#262626",
            marginBottom: 8,
            whiteSpace: "pre-line",
          }}
        >
          {t("chooseRate")}
        </Title>
        <Rate
          texts={t("rate.0", { returnObjects: true })}
          price={39}
          discountedPrice={29.99}
          isRtl={isRtl}
          discount={30}
          name="sprint"
          disabled
        />
        <Rate
          texts={t("rate.1", { returnObjects: true })}
          price={89.99}
          isRtl={isRtl}
          name="marafon"
          disabled
        />
      </Container>
      <Container
        align="left"
        styles={{
          padding: "64px 0 24px",
          background: "#FFB612",
        }}
      >
        <HaveQuestion style={{ marginTop: 0 }}>
          {t("doYouHaveQuestion")}
        </HaveQuestion>
        <SocialButtons>
          <Amplitude>
            {({ logEvent }) => (
              <SocialButton
                onClick={() => {
                  logEvent("landing_tap_contact", { where: "email" });
                }}
                href={t("supportLink")}
              >
                <EmailYellow />
                <SocialButtonText>{t("email")}</SocialButtonText>
              </SocialButton>
            )}
          </Amplitude>
          <Amplitude>
            {({ logEvent }) => (
              <SocialButton
                target="_blank"
                onClick={() => {
                  logEvent("landing_tap_contact", { where: "facebook" });
                }}
                href="https://www.facebook.com/Bright-English-for-beginners-526887920976655/"
              >
                <FacebookYellow />
                <SocialButtonText>{t("facebook")}</SocialButtonText>
              </SocialButton>
            )}
          </Amplitude>
        </SocialButtons>
        <Amplitude>
          {({ logEvent }) => (
            <AppStoreContainer
              href="https://itunes.apple.com/app/bright-english-for-beginners/id1258621855"
              target="_blank"
              onClick={() => {
                logEvent("landing_tap_download");
              }}
            >
              <AppStoreEN />
            </AppStoreContainer>
          )}
        </Amplitude>
        <Terms currentLanguage={currentLanguage} />
        <CopyRight />
      </Container>
    </div>
  );
}

export default LandingEnglishVersion;
