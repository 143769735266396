import styled from "styled-components";

export const ErrorPayStyled = styled.div``;

export const Top = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Title = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 120%;
  color: #262626;
  margin-left: 20px;
  ${({ isRtl }) => (isRtl ? "margin-right: 20px" : "margin-left: 20px")};
`;

export const WrapperMan = styled.div`
  margin: 62px auto 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OopsMessage = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 120%;
  color: #262626;
  margin-bottom: 15px;
  text-align: center;
  white-space: pre-line;
`;

export const WeWorking = styled.div`
  font-size: 15px;
  margin-bottom: 68px;
  line-height: 18px;
  text-align: center;
  color: #4a4a4a;
  white-space: pre-line;
  padding: 0 20px;
`;

export const WrapperAppStore = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
`;
