import styled from "styled-components";

export const TrapeziodContainer = styled.div`
  position: relative;
  margin-top: -32px;
`;

export const TrapezoidSyled = styled.div`
  position: relative;
  width: ${({ width }) => width}px;
  height: 100px;
  margin: 0 auto;
  transform: perspective(50em) rotateX(60deg);
  background: linear-gradient(180deg, #ffbf1a 1.77%, #ffa31a 100%);
  border-radius: 7px;

  ::before,
  ::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 95%;
    z-index: 0;
    border-top: ${({ traingleHeight }) => traingleHeight}px solid #e1871c;
  }

  ::before {
    border-left: ${({ triangleWidth }) => triangleWidth}px solid transparent;
    left: 0px;
  }
  ::after {
    border-right: ${({ triangleWidth }) => triangleWidth}px solid transparent;
    right: 0px;
  }
`;

export const TrapezoidText = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  white-space: pre-line;
  text-align: center;
`;

export const TrapezoidGreenText = styled(TrapezoidText)`
  font-size: 23px;
`;

export const TrapeziodGreenContainer = styled.div`
  position: relative;
  margin-top: -46px;
`;

export const TrapezoidGreenSyled = styled.div`
  position: relative;
  width: 250px;
  height: 142px;
  margin: 0 auto;
  transform: perspective(50em) rotateX(60deg);
  background: linear-gradient(180deg, #73cd01 0%, #01b719 100%);
  border-radius: 7px;

  ::before {
    content: "";
    position: absolute;
    top: 77%;
    height: 108px;
    left: 7px;
    right: 7px;
    border-radius: 0px 0px 15px 15px;
    background: linear-gradient(180deg, #1ea210 0%, #62ae02 100%);
    transform: perspective(50em) rotateX(-62deg);
  }
`;
