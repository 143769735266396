import { useTranslation } from "react-i18next";
import {
  MenuStyled,
  Title,
  Link,
  LinkImg,
  LinkText,
  MenuContainer,
} from "./style";

import star from "../../assets/star.svg";
import b from "../../assets/b.svg";
import cup from "../../assets/cup.svg";
import mark from "../../assets/mark.svg";
import thumpup from "../../assets/thumpup.svg";
import questio from "../../assets/questio.svg";
import { Amplitude } from "@amplitude/react-amplitude";

const getElmPosition = (href) =>
  document.querySelector(href).getBoundingClientRect().top;

export const Menu = ({ isOpen, onClose, isRtl, updateWaypoint }) => {
  const { t } = useTranslation("landing");

  if (isOpen) {
    document.body.scrollTo({ top: document.body.scrollTop });
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = null;
  }

  const linkList = [
    {
      icon: star,
      text: t("menuAboutApp"),
      href: "#menuAboutApp",
      waypoint: 0,
    },
    {
      icon: b,
      text: t("menuInterval"),
      href: "#menuInterval",
      waypoint: 2,
    },
    {
      icon: cup,
      text: t("menuHowItWork"),
      href: "#menuHowItWork",
      waypoint: 4,
    },
    {
      icon: mark,
      text: t("menuStart"),
      href: "#menuStart",
      waypoint: 8,
    },
    {
      icon: thumpup,
      text: t("menuFeedback"),
      href: "#menuFeedback",
      waypoint: 9,
    },
    {
      icon: questio,
      text: t("menuQuestion"),
      href: "#menuQuestion",
      waypoint: 10,
    },
  ];

  const onClick = (e, href) => {
    e.preventDefault();
    const elmPosition = getElmPosition(href);
    window.scrollBy({ top: elmPosition, left: 0, behavior: "smooth" });
    onClose();
  };

  return (
    <MenuStyled isOpen={isOpen}>
      <MenuContainer>
        <Title>{t("menuTitle")}</Title>
        {linkList.map((link, index) => (
          <Amplitude key={link.href}>
            {({ logEvent }) => (
              <Link
                onClick={(e) => {
                  logEvent("landing_tap_menu_item", { index: index + 1 });
                  updateWaypoint(link.waypoint);
                  onClick(e, link.href);
                }}
                href={link.href}
              >
                <LinkImg src={link.icon} />
                <LinkText isRtl={isRtl}>{link.text}</LinkText>
              </Link>
            )}
          </Amplitude>
        ))}
      </MenuContainer>
    </MenuStyled>
  );
};
