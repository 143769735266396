import styled from "styled-components";
import backgroundImg from "./icons/background.svg";
import receipt from "./icons/receipt-top.svg";

export const SuccessPayStyled = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${backgroundImg});
  background-position: top center;
  background-repeat: no-repeat;
  margin: 0px -32px;
  padding: 82px 32px 0;
`;

export const ThanksForBuy = styled.h3`
  font-weight: bold;
  font-size: 28px;
  line-height: 120%;
  margin: 66px 0 40px;
  text-align: center;
  color: #ffffff;
  white-space: pre-line;
`;

export const ForDownload = styled.div`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  white-space: pre-line;
  margin-bottom: 24px;
`;

export const CopyMessage = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-top: 24px;
  white-space: pre-line;
  margin-bottom: 53px;
`;

export const InfoAboutPay = styled.div`
  background: #f5f2eb;
  padding: 24px 16px 21px;
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 53px;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    top: -12px;
    left: 0;
    right: 0;
    width: 100%;
    height: 12px;
    background: url(${receipt}) no-repeat center;
    background-size: cover;
  }

  ::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 12px;
    bottom: -12px;
    transform: rotate(180deg);
    background: url(${receipt}) no-repeat center;
    background-size: cover;
  }
`;

export const InfoAboutPayTitle = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #262626;
  padding-bottom: 24px;
  border-bottom: 1px solid #989898;
  text-align: left;
`;

export const InfoAboutPayBody = styled.div`
  margin-top: 24px;
`;

export const InfoAboutPayBodyItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;

  :last-child {
    margin-bottom: 0;
  }
`;

export const InfoAboutPayBodyItemName = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #262626;
`;

export const InfoAboutPayBodyItemValue = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #262626;
`;

export const IfDontAccess = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px;
  white-space: pre-line;
`;

export const JustClick = styled.div`
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  color: #ffffff;
`;

export const Footer = styled.div`
  color: #fff;
`;

export const YellowLink = styled.a`
  color: #ffb612;
  font-weight: 700;
  text-decoration: underline;
`;

export const NoObligation = styled.div`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  white-space: pre-line;
  & > a {
    font-size: 14px;
    line-height: 19px;
  }
`;
