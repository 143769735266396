import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const TermsAndPrivacy = styled.div`
  display: flex;
  margin-top: 24px;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ isRtl, isRus }) =>
    isRtl ? "row-reverse" : isRus ? "column" : "row"};

  ${({ isRus }) => {
    if (isRus) {
      return css`
        & {
          display: flex;
          gap: 20px;
        }
        & > a:first-child {
          border: none;
          margin: 0;
          padding: 0;
        }
      `;
    }

    return "";
  }}
`;

export const TermsAndPrivacyText = styled(Link)`
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;

  :first-child {
    padding-right: 16px;
    margin-right: 16px;
    border-right: 2px solid rgba(255, 255, 255, 0.4);
  }
`;
