import { useTranslation } from "react-i18next";
import {
  CheckboxStyled,
  CheckboxInput,
  CheckboxLabel,
  CheckboxLabelText,
  Link,
} from "./style";

export const Checkbox = ({
  placeholder,
  formRef,
  name,
  isError,
  isRtl,
  language,
}) => {
  const { t } = useTranslation("landing");
  return (
    <CheckboxStyled>
      <CheckboxInput
        id={`checkbox_${name}`}
        type="checkbox"
        ref={formRef}
        name={name}
        placeholder={placeholder}
      />
      <CheckboxLabel isError={isError} htmlFor={`checkbox_${name}`} />
      {language === "es" ? (
        <CheckboxLabelText isRtl={isRtl} htmlFor={`checkbox_${name}`}>
          {t("iAgree")}{" "}
          <Link
            target="_blank"
            to={
              "//appspeechen.s3.us-west-002.backblazeb2.com/iOS/LandingOffer/privacidad_bright.pdf"
            }
          >
            {t("conditionUse")}
          </Link>
          {", "}
          <br />
          <Link
            target="_blank"
            to={
              "//appspeechen.s3.us-west-002.backblazeb2.com/iOS/LandingOffer/condiciones_bright.pdf"
            }
          >
            {t("policy")}
          </Link>
          <br />
          {t("and")}{" "}
          <Link
            target="_blank"
            to="//appspeechen.s3.us-west-002.backblazeb2.com/iOS/LandingOffer/devolucion_bright.pdf"
          >
            {t("moneyBack")}
          </Link>
          {", "}
        </CheckboxLabelText>
      ) : (
        <CheckboxLabelText isRtl={isRtl} htmlFor={`checkbox_${name}`}>
          {t("iAgree")}{" "}
          <Link to={`/terms-of-use/${language}`}>{t("conditionUse")}</Link>{" "}
          <br />
          {t("and")}{" "}
          <Link to={`/privacy-policy/${language}`}>{t("policy")}</Link>
        </CheckboxLabelText>
      )}
    </CheckboxStyled>
  );
};
