import { useTranslation, Trans } from "react-i18next";
import i18n from "./i18n";
import { Container } from "./components/Container";
import { Title } from "./components/Title";
import { ReactComponent as Logo } from "./assets/logo.svg";
import { Burger } from "./components/Burger";
import { Terms } from "./components/Terms/Terms";
import { CopyRight } from "./components/CopyRight/CopyRight";
import {
  Description,
  DescriptionBold,
  IphoneImg,
  YellowText,
  FlyingMan,
  BringWasCreated,
  TimeOfTrainingContainer,
  TimeOfTraining,
  TimeOfTrainingNumber,
  TimeOfTrainingSpend,
  ArrowImg,
  WomanWithStrollerImg,
  OurTraining,
  FoundationOfLanguage,
  DoUKnow,
  DoUKnowTitle,
  DoUKnowForSpeak,
  DoUKnowFirstWords,
  BrainLazyDescription,
  ListOfReason,
  Reason,
  ReasonImg,
  ReasonText,
  MagicInAction,
  NameOfMethod,
  PollImage,
  PolPimsler,
  Proof,
  ListWithImage,
  ListWithImageItem,
  ListWithImageItemImg,
  ListWithImageText,
  ListWithImageTextHighSpeed,
  BrightVideo,
  ListWithImageDesc,
  ListWithImageTextContainer,
  ListWithImageAdvantagesTitle,
  ListWithImageItemAdvantages,
  PeopleImg,
  HaveQuestion,
  SocialButtons,
  SocialButton,
  SocialButtonText,
  AppStoreContainer,
  FAQImg,
  WrapperFotFlyingMan,
  CornerWrapper,
  WrapperForQuestion,
  QuestionImgFloating,
  FlyingWoman,
} from "./style";
import { Button } from "./components/Button";
import { Benefits } from "./components/Benefits";
import iphone from "./assets/iphone.png";
import ESiphone from "./assets/es-iphone.png";
import ZHiphone from "./assets/zh-iphone.png";
import ARiphone from "./assets/ar-iphone.png";
import man from "./assets/man.png";
import arrow from "./assets/arrow.svg";
import womanWithStroller from "./assets/womanWithStroller.svg";
import snail from "./assets/snail.svg";
import clock from "./assets/clock.svg";
import arrows from "./assets/arrows.svg";
import { AboutBright } from "./components/AboutBright";
import { Trapezoid } from "./components/Trapezoid";
import { TrapezoidGreen } from "./components/Trapezoid/TrapezoidGreen";
import brain from "./assets/lottie/brain.json";
import poll from "./assets/poll.png";
import book from "./assets/book.svg";
import timer from "./assets/timer.svg";
import boredWoman from "./assets/boredWoman.svg";
import words from "./assets/words.svg";
import notify from "./assets/notify.svg";
import smileWoman from "./assets/smileWoman.svg";
import adaptability from "./assets/adaptability.svg";
import fire from "./assets/fire.svg";
import darts from "./assets/darts.svg";
import weaknesses from "./assets/weaknesses.svg";
import guarantee from "./assets/guarantee.svg";
import faq from "./assets/faq.svg";
import people from "./assets/people.svg";
import question from "./assets/question.svg";
import questionYellow from "./assets/questionYellow.svg";
import flyingWoman from "./assets/flyingWoman.svg";
import { ReactComponent as EmailYellow } from "./assets/mailYellow.svg";
import { ReactComponent as FacebookYellow } from "./assets/facebookYellow.svg";
import { ReactComponent as AppStoreRU } from "./assets/ru_app-store_white.svg";
import { ReactComponent as AppStoreES } from "./assets/es_app-store_white.svg";
import { ReactComponent as AppStoreAR } from "./assets/ar_app-store_white.svg";
import { ReactComponent as AppStoreZH } from "./assets/zh_app-store_white.svg";
import trainingBackground from "./assets/trainingBackground.svg";
import { TrainingSlider } from "./components/TrainingSection";
import { isFacebookApp, Rate } from "./components/Rate";
import { FeedbackSlider } from "./components/FeedbackSlider";
import { AnswerQuestion } from "./components/AnswerQuestion";
import { Menu } from "./components/Menu";
import { useLayoutEffect, useState, useRef, useEffect } from "react";
import lottie from "lottie-web";
import smoothscroll from "smoothscroll-polyfill";
import { Waypoint } from "react-waypoint";
import "@lottiefiles/lottie-player";
import { create } from "@lottiefiles/lottie-interactivity";
import { Controller, Scene } from "react-scrollmagic";
import { Tween } from "react-gsap";
import { rtlDetect } from "./libs/isRtl";
import { dic, LOCAL_STORAGE_KEY_LANDING_SHOW_TIME } from "./App";
import { Amplitude } from "@amplitude/react-amplitude";
import amplitude from "amplitude-js";
import { useLocation } from "react-router";

let brainLottie = null;

const getElmPosition = (href) =>
  document.querySelector(href).getBoundingClientRect().top;

const dictionaryMagicGrowAnimation = {
  es: "https://assets8.lottiefiles.com/packages/lf20_mdda74ek.json",
  ru: "https://assets2.lottiefiles.com/packages/lf20_tbru4dz9.json",
  "zh-cn": "https://assets10.lottiefiles.com/packages/lf20_jnnknf3d.json",
  ar: "https://assets6.lottiefiles.com/packages/lf20_hgy7t5ao.json",
};

const dictionaryIphones = {
  ru: iphone,
  es: ESiphone,
  "zh-cn": ZHiphone,
  ar: ARiphone,
};

const dictionaryAppStore = {
  ru: AppStoreRU,
  es: AppStoreES,
  "zh-cn": AppStoreZH,
  ar: AppStoreAR,
};

const videos = {
  ru: "https://appspeechen.s3.us-west-002.backblazeb2.com/iOS/LandingOffer/landing_speed_ru.mp4",
  es: "https://appspeechen.s3.us-west-002.backblazeb2.com/iOS/LandingOffer/landing_speed_es-419.mp4",
  "zh-cn":
    "https://cdn.discordapp.com/attachments/392384501939961869/725362721364639794/CN_Phone1.mp4",
  ar: "https://appspeechen.s3.us-west-002.backblazeb2.com/iOS/LandingOffer/landing_speed_ar.mp4",
};

const addMinutes = (date, minutes) =>
  new Date(date.getTime() + minutes * 60000).getTime();

function Landing() {
  const { t } = useTranslation("landing");
  const [isBlack, setBlack] = useState(false);
  const [isOpenMenu, setOpenMenu] = useState(false);
  const magicGrowUp = useRef(null);
  const location = useLocation();
  const language = i18n.language.split("-")[0];
  const currentLanguage = dic[language] || "ru";

  const isRtl = rtlDetect();

  const AppStore = dictionaryAppStore[currentLanguage];

  useLayoutEffect(() => {
    document.title = t("titlePage");

    const changeBurgerColor = () => {
      const heightOfWhiteBurger =
        document.getElementById("firstSection").clientHeight;
      if (window.scrollY >= heightOfWhiteBurger - 60) {
        if (!isBlack) {
          setBlack(true);
        }
      }
      if (window.scrollY <= heightOfWhiteBurger - 60) {
        setBlack(false);
      }
    };
    smoothscroll.polyfill();

    document.addEventListener("scroll", changeBurgerColor);

    magicGrowUp.current.addEventListener("load", () => {
      create({
        mode: "scroll",
        player: "#magicGrowUp",
        actions: [
          {
            visibility: [0.2, 0.6],
            type: "seek",
            frames: [0, 43],
          },
        ],
      });
    });

    if (brainLottie === null) {
      brainLottie = lottie.loadAnimation({
        container: document.getElementById("brainLottie"),
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: brain,
      });
    }

    return () => {
      document.removeEventListener("scroll", changeBurgerColor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchFTPRedirect = async () => {
      try {
        const deviceId = localStorage.getItem("deviceId");
        const { redirectUrl } = await fetch(
          `${process.env.REACT_APP_PUBLIC_URL}/app-portal/landing/redirectstart/${currentLanguage}/${deviceId}`,
          {
            method: "post",
            headers: {
              "Content-type": "application/json",
            },
          }
        ).then((response) => {
          return response.json();
        });

        localStorage.setItem("BrightRedirectUrl", redirectUrl);
      } catch (error) {
        console.error(error);
      }
    };

    const landingShowTime = localStorage.getItem(
      LOCAL_STORAGE_KEY_LANDING_SHOW_TIME
    );
    if (!landingShowTime) {
      amplitude.getInstance().logEvent("landing_show");
      localStorage.setItem(
        LOCAL_STORAGE_KEY_LANDING_SHOW_TIME,
        Date.now().toString()
      );
    } else {
      const landingShowTimePlus1Hour = addMinutes(
        new Date(Number(landingShowTime)),
        60
      );
      if (Date.now() > landingShowTimePlus1Hour) {
        amplitude.getInstance().logEvent("landing_show");
        localStorage.setItem(
          LOCAL_STORAGE_KEY_LANDING_SHOW_TIME,
          Date.now().toString()
        );
      }
    }
    localStorage.setItem("waypoint", 0);
    localStorage.setItem("waypoint_time", Date.now());
    if (isFacebookApp()) {
      fetchFTPRedirect();
    }
  }, [currentLanguage]);

  const analiticsParamsFromUrl = [
    { name: "pid", ls: "pid" },
    { name: "utm_content", ls: "af_adset" },
    { name: "utm_term", ls: "af_ad" },
  ];

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    analiticsParamsFromUrl.map((elm) =>
      urlParams.has(elm.name)
        ? localStorage.setItem(elm.ls, urlParams.get(elm.name))
        : localStorage.removeItem(elm.ls)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timeDelayInMinute = 30;

  const updateWaypoint = (number) => {
    const lastView = parseInt(localStorage.getItem("waypoint_time"));
    const lastViewPlus30Minutes = addMinutes(
      new Date(lastView),
      timeDelayInMinute
    );
    const now = Date.now();
    const lastViewWaypoint = parseInt(localStorage.getItem("waypoint"), 10);

    if (lastViewPlus30Minutes - now > 0) {
      if (lastViewWaypoint >= number) return;
      if (number > 0) {
        localStorage.setItem("waypoint", number);
        localStorage.setItem(
          "waypoint_time",
          addMinutes(new Date(), timeDelayInMinute).toString()
        );
        amplitude
          .getInstance()
          .logEvent("landing_unit_tracker", { unit: number });
      }
    } else {
      localStorage.setItem("waypoint", number);
      localStorage.setItem("waypoint_time", Date.now().toString());
      amplitude
        .getInstance()
        .logEvent("landing_unit_tracker", { unit: number });
    }
  };

  const onClick = (e, href, waypoint) => {
    e.preventDefault();
    updateWaypoint(waypoint);
    const elmPosition = getElmPosition(href);
    window.scrollBy({ top: elmPosition, left: 0, behavior: "smooth" });
  };

  return (
    <div>
      <Menu
        isRtl={isRtl}
        onClose={() => setOpenMenu(false)}
        isOpen={isOpenMenu}
        updateWaypoint={updateWaypoint}
      />
      <Amplitude>
        {({ logEvent }) => (
          <Burger
            onClick={() => {
              if (!isOpenMenu) {
                logEvent("landing_tap_menu");
              }
              setOpenMenu(!isOpenMenu);
            }}
            isOpenMenu={isOpenMenu}
            isBlack={isBlack}
            isRtl={isRtl}
          />
        )}
      </Amplitude>
      <div id="firstSection">
        <Container
          align="center"
          styles={{
            padding: "45px 0",
            background:
              "linear-gradient(197.63deg, #784CF5 15.21%, #4C7BF5 84.84%)",
            borderBottomRightRadius: !isRtl && 56,
            borderBottomLeftRadius: isRtl && 56,
          }}
        >
          <Logo />
          <Title
            dir="auto"
            styles={{
              textTransform: "uppercase",
              color: "#fff",
              marginTop: 24,
              textAlign: "center",
              whiteSpace: "pre-line",
            }}
          >
            {t("smartApplication")}
          </Title>
          <Description>
            {t("remember")}
            <br />
            <DescriptionBold>{t("withOutProblem")}</DescriptionBold>
          </Description>
          <Amplitude>
            {({ logEvent }) => (
              <Button
                onClick={(e) => {
                  logEvent("landing_tap_go_to_purchase");
                  onClick(e, "#menuStart", 8);
                }}
                text={t("getAccess")}
              />
            )}
          </Amplitude>
          <Amplitude>
            {({ logEvent }) => (
              <Button
                styles={{ marginTop: 31 }}
                variant="secondary"
                text={t("howItWork")}
                onClick={(e) => {
                  logEvent("landing_tap_header");
                  onClick(e, "#menuInterval", 2);
                }}
              />
            )}
          </Amplitude>

          <IphoneImg src={dictionaryIphones[currentLanguage]} />
          <div id="triggerFlyingMan" />

          <AboutBright />
        </Container>
      </div>
      <CornerWrapper isRtl={isRtl}>
        <a id="menuAboutApp" />
        <WrapperFotFlyingMan>
          <Controller>
            <Scene triggerElement="#triggerFlyingMan" offset={0} duration={400}>
              {(progress) => {
                return (
                  <Tween
                    from={{
                      css: isRtl
                        ? {
                            left: "-200px",
                          }
                        : {
                            right: "-200px",
                          },
                      ease: "Strong.easeOut",
                    }}
                    to={{
                      css: isRtl
                        ? {
                            left: "-80px",
                          }
                        : {
                            right: "-80px",
                          },
                      ease: "Strong.easeOut",
                    }}
                    totalProgress={progress}
                    paused
                  >
                    <FlyingMan isRtl={isRtl} src={man} />
                  </Tween>
                );
              }}
            </Scene>
          </Controller>

          <Container
            align="left"
            styles={{
              padding: "80px 0 64px",
              background: "#fff",
              overflow: "visible",
            }}
          >
            <Title
              styles={{
                color: "#262626",
                marginBottom: 40,
                whiteSpace: "pre-line",
              }}
            >
              {t("with")} <YellowText>{t("bright")}</YellowText>
              <br />
              {t("youCan")}
            </Title>
            <Benefits isRtl={isRtl} />
          </Container>
        </WrapperFotFlyingMan>
      </CornerWrapper>
      <Waypoint
        onEnter={() => {
          updateWaypoint(1);
        }}
      />
      <Container
        align="left"
        styles={{
          padding: "64px 0",
          background: "#F3A614",
        }}
      >
        <Title
          styles={{
            color: "#fff",
            marginBottom: 14,
            whiteSpace: "pre-line",
          }}
        >
          {t("notTime")}
        </Title>
        <BringWasCreated>{t("BrightWasCreated")}</BringWasCreated>
        <TimeOfTrainingContainer>
          <TimeOfTraining>
            <TimeOfTrainingNumber>1</TimeOfTrainingNumber>
            <TimeOfTrainingSpend>{t("lesson")}</TimeOfTrainingSpend>
          </TimeOfTraining>
          <ArrowImg isRtl={isRtl} src={arrow} />
          <TimeOfTraining>
            <TimeOfTrainingNumber>10</TimeOfTrainingNumber>
            <TimeOfTrainingSpend>{t("minutes")}</TimeOfTrainingSpend>
          </TimeOfTraining>
          <ArrowImg isRtl={isRtl} src={arrow} />
          <TimeOfTraining>
            <TimeOfTrainingNumber>8</TimeOfTrainingNumber>
            <TimeOfTrainingSpend>{t("words")}</TimeOfTrainingSpend>
          </TimeOfTraining>
        </TimeOfTrainingContainer>
        <WomanWithStrollerImg isRtl={isRtl} src={womanWithStroller} />
        <OurTraining>{t("ourTraining")}</OurTraining>
      </Container>
      <a id="menuInterval" />
      <Waypoint
        onEnter={() => {
          updateWaypoint(2);
        }}
      />
      <div id="trigger" />
      <Container
        styles={{
          padding: "64px 0",
          background: "#fff",
        }}
      >
        <Title
          styles={{
            color: "#262626",
            marginBottom: 32,
            whiteSpace: "pre-line",
            textAlign: "center",
          }}
        >
          {t("mostImportant")}
        </Title>
        <Trapezoid
          text={t("writing")}
          width={155}
          traingleHeight={12}
          triangleWidth={6}
        />
        <Trapezoid
          text={t("grammar")}
          width={190}
          traingleHeight={22}
          triangleWidth={8}
        />
        <Trapezoid
          text={t("pronunciation")}
          width={223}
          traingleHeight={32}
          triangleWidth={10}
        />
        <TrapezoidGreen text={t("wordsAndExpressions")} />
        <FoundationOfLanguage>
          <Trans t={t} i18nKey="wordsAndExpressionsItIs" />
        </FoundationOfLanguage>

        <Controller>
          <Scene triggerElement="#trigger" offset={200} duration={1000}>
            {(progress) => {
              return (
                <Tween
                  from={{
                    css: isRtl
                      ? {
                          left: "-200px",
                        }
                      : {
                          right: "-200px",
                        },
                    ease: "Strong.easeOut",
                  }}
                  to={{
                    css: isRtl
                      ? {
                          left: "-30px",
                        }
                      : {
                          right: "-30px",
                        },
                    ease: "Strong.easeOut",
                  }}
                  totalProgress={progress}
                  paused
                >
                  <FlyingWoman isRtl={isRtl} src={flyingWoman} />
                </Tween>
              );
            }}
          </Scene>
        </Controller>
        <DoUKnow>
          <DoUKnowTitle>{t("doYouKnow")}</DoUKnowTitle>
          <DoUKnowForSpeak>
            <Trans>{t("forSpeak")}</Trans>
          </DoUKnowForSpeak>
          <DoUKnowFirstWords>{t("firstWords")}</DoUKnowFirstWords>
          <Amplitude>
            {({ logEvent }) => (
              <Button
                styles={{ textTransform: "inherit", fontSize: 18 }}
                text={t("startLearn")}
                onClick={(e) => {
                  logEvent("landing_tap_start");
                  onClick(e, "#menuStart", 7);
                }}
              />
            )}
          </Amplitude>
        </DoUKnow>
      </Container>
      <Container
        align="left"
        styles={{
          padding: "64px 0",
          background: "#fff",
          overflow: "visible",
        }}
      >
        <Title
          styles={{
            color: "#262626",
            marginBottom: 0,
            whiteSpace: "pre-line",
          }}
        >
          {t("youBrainIsLazy")}
        </Title>
        <BrainLazyDescription>{t("forgottenInNextDay")}</BrainLazyDescription>
        <div id="brainLottie"></div>
        <Waypoint
          bottomOffset={window.outerHeight / 2 - 100}
          onEnter={() => brainLottie.play()}
        />
        <ListOfReason>
          <Reason>
            <ReasonImg src={clock} />
            <ReasonText isRtl={isRtl}>{t("flashMemory")}</ReasonText>
          </Reason>
          <Reason>
            <ReasonImg src={arrows} />
            <ReasonText isRtl={isRtl}>{t("needRemember")}</ReasonText>
          </Reason>
          <Reason>
            <ReasonImg src={snail} />
            <ReasonText isRtl={isRtl}>{t("youDictionary")}</ReasonText>
          </Reason>
        </ListOfReason>
      </Container>
      <Waypoint
        onEnter={() => {
          updateWaypoint(3);
        }}
      />
      <div
        style={{
          background:
            "linear-gradient(180deg, #F4F1EA 0%, rgba(244, 241, 234, 0) 100%)",
        }}
      >
        <WrapperForQuestion>
          <QuestionImgFloating isRtl={isRtl} src={question} />
          <Container
            align="left"
            styles={{
              padding: "32px 0 80px",
              background: "transparent",
              overflow: "visible",
            }}
          >
            <Title
              styles={{
                color: "#262626",
                whiteSpace: "pre-line",
              }}
            >
              {t("magic")}
            </Title>
            <MagicInAction>{t("magicInAction")}</MagicInAction>
            <lottie-player
              ref={magicGrowUp}
              id="magicGrowUp"
              mode="normal"
              src={dictionaryMagicGrowAnimation[currentLanguage]}
            ></lottie-player>
            <NameOfMethod>
              <Trans t={t} i18nKey="nameOfMethod" />
            </NameOfMethod>
            <PollImage isRtl={isRtl} src={poll} />
            <PolPimsler>{t("polPimsler")}</PolPimsler>
            <Proof>{t("proof")}</Proof>
          </Container>
        </WrapperForQuestion>
      </div>
      <Waypoint
        onEnter={() => {
          updateWaypoint(4);
        }}
      />
      <a id="menuHowItWork" />
      <div style={{ background: "#FFA91F" }}>
        <WrapperForQuestion>
          <QuestionImgFloating isRtl={isRtl} src={questionYellow} />
          <Container
            align="left"
            styles={{
              padding: "64px 0",
              background: "#FFA91F",
            }}
          >
            <Title
              styles={{
                color: "#fff",
                marginBottom: 40,
                whiteSpace: "pre-line",
              }}
            >
              {t("top3")}
            </Title>
            <ListWithImage>
              <ListWithImageItem>
                <ListWithImageItemImg src={book} />
                <ListWithImageText isRtl={isRtl}>
                  {t("donUnderstand")}
                </ListWithImageText>
              </ListWithImageItem>
              <ListWithImageItem>
                <ListWithImageItemImg src={timer} />
                <ListWithImageText isRtl={isRtl}>
                  {t("itsHard")}
                </ListWithImageText>
              </ListWithImageItem>
              <ListWithImageItem>
                <ListWithImageItemImg src={boredWoman} />
                <ListWithImageText isRtl={isRtl}>
                  {t("itsBored")}
                </ListWithImageText>
              </ListWithImageItem>
            </ListWithImage>
          </Container>
        </WrapperForQuestion>
      </div>
      <Waypoint
        onEnter={() => {
          updateWaypoint(5);
        }}
      />
      <Container
        align="left"
        styles={{
          padding: "64px 0",
          background: "#fff",
        }}
      >
        <Title
          styles={{
            color: "#262626",
            marginBottom: 16,
            whiteSpace: "pre-line",
          }}
        >
          {t("birghtSpeed")}
        </Title>
        <MagicInAction style={{ marginTop: 0 }}>
          {t("upgradeMethod")}
        </MagicInAction>
        <BrightVideo muted autoPlay loop playsInline>
          <source
            src={videos[currentLanguage]}
            data-wf-ignore="true"
            type="video/mp4"
          />
        </BrightVideo>
        <ListWithImage>
          <ListWithImageItem>
            <ListWithImageItemImg src={words} />
            <ListWithImageTextHighSpeed isRtl={isRtl}>
              {t("offer")}
            </ListWithImageTextHighSpeed>
          </ListWithImageItem>
          <ListWithImageItem>
            <ListWithImageItemImg src={notify} />
            <ListWithImageTextHighSpeed isRtl={isRtl}>
              {t("notify")}
            </ListWithImageTextHighSpeed>
          </ListWithImageItem>
          <ListWithImageItem>
            <ListWithImageItemImg src={smileWoman} />
            <ListWithImageTextHighSpeed isRtl={isRtl}>
              {t("motivation")}
            </ListWithImageTextHighSpeed>
          </ListWithImageItem>
        </ListWithImage>
        <PolPimsler style={{ marginTop: 56 }}>
          <Trans t={t} i18nKey="fastBrainAllow" />
        </PolPimsler>
      </Container>
      <Waypoint
        onEnter={() => {
          updateWaypoint(6);
        }}
      />
      <Container
        align="left"
        styles={{
          padding: "64px 0",
          background:
            "linear-gradient(197.63deg, #784CF5 15.21%, #4C7BF5 84.84%)",
        }}
      >
        <Title
          styles={{
            color: "#fff",
            marginBottom: 56,
            whiteSpace: "pre-line",
          }}
        >
          {t("advantages")}
        </Title>
        <ListWithImage>
          <ListWithImageItemAdvantages>
            <ListWithImageItemImg src={adaptability} />
            <ListWithImageTextContainer isRtl={isRtl}>
              <ListWithImageAdvantagesTitle>
                {t("adaptability.title")}
              </ListWithImageAdvantagesTitle>
              <ListWithImageDesc>{t("adaptability.desc")}</ListWithImageDesc>
            </ListWithImageTextContainer>
          </ListWithImageItemAdvantages>
          <ListWithImageItemAdvantages>
            <ListWithImageItemImg src={fire} />
            <ListWithImageTextContainer isRtl={isRtl}>
              <ListWithImageAdvantagesTitle>
                {t("native.title")}
              </ListWithImageAdvantagesTitle>
              <ListWithImageDesc>{t("native.desc")}</ListWithImageDesc>
            </ListWithImageTextContainer>
          </ListWithImageItemAdvantages>
          <ListWithImageItemAdvantages>
            <ListWithImageItemImg src={darts} />
            <ListWithImageTextContainer isRtl={isRtl}>
              <ListWithImageAdvantagesTitle>
                {t("onlyNeeded.title")}
              </ListWithImageAdvantagesTitle>
              <ListWithImageDesc>{t("onlyNeeded.desc")}</ListWithImageDesc>
            </ListWithImageTextContainer>
          </ListWithImageItemAdvantages>
          <ListWithImageItemAdvantages>
            <ListWithImageItemImg src={weaknesses} />
            <ListWithImageTextContainer isRtl={isRtl}>
              <ListWithImageAdvantagesTitle>
                {t("weaknesses.title")}
              </ListWithImageAdvantagesTitle>
              <ListWithImageDesc>{t("weaknesses.desc")}</ListWithImageDesc>
            </ListWithImageTextContainer>
          </ListWithImageItemAdvantages>
        </ListWithImage>
      </Container>
      <Waypoint
        onEnter={() => {
          updateWaypoint(7);
        }}
      />
      <Container
        align="center"
        styles={{
          padding: "64px 0",
          background: "#fff",
          backgroundImage: `url(${trainingBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center 100px",
        }}
      >
        <Title
          styles={{
            color: "#262626",
            marginBottom: 50,
            whiteSpace: "pre-line",
            textAlign: "center",
          }}
        >
          {t("training")}
        </Title>
        <TrainingSlider isRtl={isRtl} />
      </Container>
      <Waypoint
        onEnter={() => {
          updateWaypoint(8);
        }}
      />
      <a id="menuStart" />
      <Container
        align="left"
        styles={{
          padding: "57px 0 152px",
          background: "#F5F1EB",
          overflow: "visible",
        }}
      >
        <Title
          styles={{
            color: "#262626",
            marginBottom: 8,
            whiteSpace: "pre-line",
          }}
        >
          {t("chooseRate")}
        </Title>
        <Rate
          texts={t("rate.0", { returnObjects: true })}
          price={39}
          discountedPrice={29.99}
          isRtl={isRtl}
          discount={30}
          name="sprint"
        />
        <Rate
          texts={t("rate.1", { returnObjects: true })}
          price={89.99}
          isRtl={isRtl}
          name="marafon"
        />
        <Title
          styles={{
            color: "#262626",
            marginBottom: 24,
            marginTop: 33,
            whiteSpace: "pre-line",
            backgroundImage: `url(${guarantee})`,
            backgroundPosition: isRtl ? "left" : "right",
            backgroundRepeat: "no-repeat",
            marginRight: !isRtl && 20,
            marginLeft: isRtl && 20,
            paddingBottom: isRtl ? 45 : 0,
            backgroundSize: "contain",
          }}
        >
          {t("guarantee")}
        </Title>
        <MagicInAction style={{ marginTop: 0 }}>
          {t("ourGuarantee")}
        </MagicInAction>
        <PeopleImg src={people} />
      </Container>
      <Waypoint
        onEnter={() => {
          updateWaypoint(9);
        }}
      />
      <Container
        align="left"
        styles={{
          padding: "64px 0",
          background: "#fff",
        }}
      >
        <a id="menuFeedback" />
        <Title
          styles={{
            color: "#262626",
            marginBottom: 32,
            whiteSpace: "pre-line",
          }}
        >
          {t("experience")}
        </Title>
        <FeedbackSlider isRtl={isRtl} />
      </Container>
      <a id="menuQuestion" />
      <Waypoint
        onEnter={() => {
          updateWaypoint(10);
        }}
      />
      <Container
        align="left"
        styles={{
          padding: "64px 0 24px",
          background: "#FFB612",
        }}
      >
        <Title
          styles={{
            color: "#fff",
            marginBottom: 49,
            whiteSpace: "pre-line",
            paddingRight: !isRtl && 120,
            paddingLeft: isRtl && 120,
          }}
        >
          {t("FAQTitle")}
        </Title>
        <div style={{ position: "relative" }}>
          <FAQImg isRtl={isRtl} src={faq} />
          {t("FAQ", { returnObjects: true }).map(
            ({ question, answer }, index) => (
              <AnswerQuestion
                key={question}
                question={question}
                answer={answer}
                index={index + 1}
              />
            )
          )}
        </div>
        <HaveQuestion>{t("doYouHaveQuestion")}</HaveQuestion>
        <SocialButtons>
          <Amplitude>
            {({ logEvent }) => (
              <SocialButton
                onClick={() => {
                  logEvent("landing_tap_contact", { where: "email" });
                }}
                href={t("supportLink")}
              >
                <EmailYellow />
                <SocialButtonText>{t("email")}</SocialButtonText>
              </SocialButton>
            )}
          </Amplitude>
          <Amplitude>
            {({ logEvent }) => (
              <SocialButton
                target="_blank"
                onClick={() => {
                  logEvent("landing_tap_contact", { where: "facebook" });
                }}
                href="https://www.facebook.com/Bright-English-for-beginners-526887920976655/"
              >
                <FacebookYellow />
                <SocialButtonText>{t("facebook")}</SocialButtonText>
              </SocialButton>
            )}
          </Amplitude>
        </SocialButtons>
        <Amplitude>
          {({ logEvent }) => (
            <AppStoreContainer
              href="https://itunes.apple.com/app/bright-english-for-beginners/id1258621855"
              target="_blank"
              onClick={() => {
                logEvent("landing_tap_download");
              }}
            >
              <AppStore />
            </AppStoreContainer>
          )}
        </Amplitude>
        <Terms currentLanguage={currentLanguage} />
        <CopyRight />
      </Container>
      <Waypoint
        onEnter={() => {
          updateWaypoint(11);
        }}
      />
    </div>
  );
}

export default Landing;
