import styled from "styled-components";

export const Selected = styled.div`
  margin-top: 46px;
  padding-bottom: 4px;
`;

export const Tariff = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #262626;
  opacity: 0.5;
`;

export const NameOfTariffAndPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
`;

export const NameOfTariff = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
`;

export const Price = styled(NameOfTariff)`
  padding-left: ${({ isDiscount }) => (isDiscount ? 8 : 0)}px;
  padding-right: ${({ isDiscount }) => (isDiscount ? 8 : 0)}px;
  font-size: 20px;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    height: 2px;
    background: #e8573e;
    display: ${({ isDiscount }) => (isDiscount ? "block" : "none")};
  }
`;

export const Benefit = styled.div`
  display: flex;
  align-items: center;
  margin-top: 18px;

  & > svg {
    flex-shrink: 0;
  }
`;

export const BenefitText = styled.div`
  line-height: 19px;
  color: #525252;

  ${({ isRtl }) => (isRtl ? "margin-right: 24px" : "margin-left: 24px")};
`;

export const Checkbox = styled.input``;

export const WrapperCheckbox = styled.div`
  margin: 20px 0 24px;
`;

export const DiscountPrice = styled(Price)`
  ${({ isRtl }) => (isRtl ? "margin-right: 10px" : "margin-left: 10px")};
`;
