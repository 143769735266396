import amplitude from "amplitude-js";

export const createSubscription = async ({
  customerId,
  paymentMethodId,
  priceId,
  locale,
  productId,
  how,
}) => {
  let adSource = localStorage.getItem("pid");
  const afAdset = localStorage.getItem("af_adset");
  const afAd = localStorage.getItem("af_ad");
  if (!adSource) adSource = "Blog";
  const appsFlyerCUID = localStorage.getItem("appsFlyerCUID");
  const result = await fetch(
    `${process.env.REACT_APP_PUBLIC_URL}/app-portal/landing/stripe/create-subscription`,
    {
      method: "post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        customerId,
        paymentMethodId,
        priceId,
        locale,
        productId,
        how,
        appsFlyerCUID,
        deviceId: amplitude.getInstance().options.deviceId,
        adSource,
        afAdset,
        afAd,
      }),
    }
  ).then((response) => {
    return response.json();
  });
  return result;
};
