import styled from "styled-components";
import checkboxMark from "../../pages/ErrorPay/icons/checkboxMark.svg";
import { Link as LinkRouter } from "react-router-dom";

export const CheckboxStyled = styled.div`
  display: flex;
`;

export const CheckboxInput = styled.input`
  display: none;

  :checked + label {
    background-image: url(${checkboxMark});

    background-color: #ffb612;
    border-color: #3898ec;
  }
`;

export const CheckboxLabel = styled.label`
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: 1px solid ${({ isError }) => (isError ? "#e8573e" : "#d5d5d5")};
  box-sizing: border-box;
  border-radius: 4px;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.2s ease-out;
  flex-shrink: 0;
`;

export const CheckboxLabelText = styled.label`
  font-size: 13px;
  line-height: 18px;
  ${({ isRtl }) => (isRtl ? "margin-right: 16px" : "margin-left: 16px")};
`;

export const Link = styled(LinkRouter)`
  color: #00a1f0;
  text-decoration: none;
  cursor: pointer;
  font-size: 13px;
  line-height: 18px;
`;
