import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ru from "./localize/ru/index.json";
import es from "./localize/es/index.json";
import zh from "./localize/zh/index.json";
import ar from "./localize/ar/index.json";
import en from "./localize/en/index.json";

export const resources = {
  ru,
  es,
  zh,
  ar,
  en
};

i18n
  .use(initReactI18next)
  .init({
    load: "languageOnly",
    cleanCode: true,
    resources,
    whitelist: ["ru", "es", "zh", "ar", 'en'],
    lng: "ru",
    fallbackLng: "ru",
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
