import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
// import reportWebVitals from "./reportWebVitals";
import { Normalize } from "styled-normalize";
import amplitude from "amplitude-js";
import { AmplitudeProvider } from "@amplitude/react-amplitude";

ReactDOM.render(
  <React.StrictMode>
    <Normalize />
    <AmplitudeProvider
      amplitudeInstance={amplitude.getInstance()}
      apiKey={process.env.REACT_APP_AMPLITUDE_KEY}
    >
      <Router>
        <App />
      </Router>
    </AmplitudeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
