import styled from "styled-components";

export const RateStyled = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  position: relative;
  padding: 24px;
  margin-top: 16px;
`;

export const RateDiscount = styled.div`
  width: 70px;
  height: 70px;
  position: absolute;
  top: -8px;
  ${({ isRtl }) => (isRtl ? "left: -8px" : "right: -8px")};
  border-radius: 50%;
  background: #e8573e;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Manrope";
  font-size: 20px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-weight: 800;
`;

export const RateTitle = styled.h3`
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 27px;
  color: #262626;
  margin-top: 0;
`;

export const RateBenefitContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: end;

  > svg {
    flex-shrink: 0;
  }
`;

export const RateBenefit = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: #262626;
  white-space: pre-line;
  ${({ isRtl }) => (isRtl ? "margin-right: 10px" : "margin-left: 10px")};
`;

export const RatePriceContainer = styled.div`
  margin-top: 4px;
  margin-bottom: 24px;
  display: flex;
`;

export const RatePrice = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: #262626;
  padding-left: ${({ isDiscount }) => (isDiscount ? 8 : 0)}px;
  padding-right: ${({ isDiscount }) => (isDiscount ? 8 : 0)}px;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    height: 2px;
    background: #e8573e;
    display: ${({ isDiscount }) => (isDiscount ? "block" : "none")};
  }
`;

export const RatePriceDiscountedPrice = styled(RatePrice)`
  ${({ isRtl }) => (isRtl ? "margin-right: 10px" : "margin-left: 10px")};
`;
