import {
  TrapeziodGreenContainer,
  TrapezoidGreenSyled,
  TrapezoidGreenText,
} from "./style";

export const TrapezoidGreen = ({ text = "no text" }) => {
  return (
    <TrapeziodGreenContainer>
      <TrapezoidGreenSyled />
      <TrapezoidGreenText>{text}</TrapezoidGreenText>
    </TrapeziodGreenContainer>
  );
};
