import { ButtonStyled, ButtonSecondaryStyled } from "./style";

export const Button = ({
  variant = "primary",
  text = "text button",
  styles = {},
  renderLike,
  onClick,
  href,
  disabled,
  target,
}) => {
  if (variant === "secondary") {
    return (
      <ButtonSecondaryStyled
        href={href}
        onClick={onClick}
        as={renderLike}
        style={styles}
        disabled={disabled}
        target={target}
      >
        {text}
      </ButtonSecondaryStyled>
    );
  }
  return (
    <ButtonStyled
      href={href}
      disabled={disabled}
      onClick={onClick}
      as={renderLike}
      style={styles}
      target={target}
    >
      {text}
    </ButtonStyled>
  );
};
