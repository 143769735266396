import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container } from "../../components/Container";
import { ReactComponent as ArrowBack } from "./icons/arrowBack.svg";
import { ReactComponent as Mark } from "./icons/mark.svg";
import { ErrorPayStyled, Title, Top } from "../ErrorPay/style";
import { useHistory, useLocation } from "react-router-dom";
import {
  Selected,
  Tariff,
  NameOfTariffAndPrice,
  NameOfTariff,
  Price,
  Benefit,
  BenefitText,
  WrapperCheckbox,
  DiscountPrice,
} from "./style";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Checkbox } from "../../components/Checkbox";
import PaymentForm from "../../components/PaymentForm";
import { useForm } from "react-hook-form";
import { rtlDetect } from "../../libs/isRtl";
import createPaymentIntent from "../../components/PaymentForm/utils/createPaymentIntent";
import createCustomer from "../../components/PaymentForm/utils/createCustomer";
import { createSubscription } from "../../components/PaymentForm/utils/createSubscription";
import i18n from "../../i18n";
import { dic } from "../../App";
import amplitude from "amplitude-js";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

const SelectedTariff = ({
  tariff,
  discountedPrice = null,
  price,
  i18keyRateIndex,
}) => {
  const { t } = useTranslation("landing");
  const history = useHistory();
  const location = useLocation();
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [user, setUser] = useState();
  const [subscription, setSubscription] = useState();
  const [isLoading, setLoading] = useState(false);
  const { register, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      agree: true,
      email: "",
    },
  });

  const agreeWatch = watch("agree");
  const emailWatch = watch("email");

  const language = dic[i18n.language.split("-")[0]] || "ru";

  useEffect(() => {
    document.title = tariff === "sprint" ? t("titleSprint") : t("titleMarafon");
  }, [t, tariff]);

  useEffect(() => {
    amplitude.getInstance().logEvent("landing_email_request_show");
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const deviceId = params.get("deviceId");
    if (deviceId) {
      localStorage.setItem("deviceId", deviceId);
      amplitude.getInstance().setDeviceId(deviceId);

      const identify = new amplitude.Identify();

      identify.set("browser_language", window.navigator.language);
      identify.set("landing_locale", language);
    }
  }, [location, language]);

  const isRtl = rtlDetect();

  async function onSubmit(data) {
    amplitude.getInstance().logEvent("landing_cart_tap_purchase", {
      product: tariff,
      email: true,
      terms: data.agree,
      email_address: data.email,
    });
    setLoading(true);
    if (tariff === "sprint") {
      const user = await createCustomer(data.email, language, tariff, tariff);
      const subscription = await createSubscription({
        customerId: user.id,
        priceId: tariff,
        locale: language,
        productId: tariff,
        how: "card",
      });
      if (user.error) {
        history.push(`/oops/${language}-pay-s`);
        setLoading(false);
        return;
      }
      setUser(user);
      setSubscription(subscription);
      setShowPaymentForm(true);
    }
    if (tariff === "marafon") {
      const user = await createPaymentIntent(
        data.email,
        language,
        tariff,
        tariff
      );
      if (user.error) {
        history.push(`/oops/${language}-pay-m`);
        setLoading(false);
        return;
      }
      setUser(user);
      setShowPaymentForm(true);
    }
    setLoading(false);
  }

  if (showPaymentForm) {
    return (
      <PaymentForm
        user={user}
        tariff={tariff}
        price={discountedPrice || price}
        goBack={() => setShowPaymentForm(false)}
        nameOfTariff={t(`rate.${i18keyRateIndex}.name`)}
        isRtl={isRtl}
        subscription={subscription?.subscription}
        clientSecret={subscription?.clientSecret}
        promoCode={subscription?.promoCode}
      />
    );
  }

  return (
    <>
      <Container
        styles={{
          padding: "18px 0",
          background: "#fff",
        }}
      >
        <ErrorPayStyled>
          <Top
            onClick={() =>
              history.length > 2
                ? history.goBack()
                : history.push(`/home/${language}-pay`)
            }
          >
            <ArrowBack
              style={{
                transform: isRtl ? "scale(-1)" : "scale(1)",
              }}
            />
            <Title isRtl={isRtl}>{t("goodChoice")}</Title>
          </Top>
          <Selected>
            <Tariff>{t("tariff")}</Tariff>
            <NameOfTariffAndPrice>
              <NameOfTariff>«{t(`rate.${i18keyRateIndex}.name`)}»</NameOfTariff>
              <div style={{ display: "flex" }}>
                <Price isDiscount={Boolean(discountedPrice)}>${price}</Price>
                {discountedPrice && (
                  <DiscountPrice isRtl={isRtl}>
                    ${discountedPrice}
                  </DiscountPrice>
                )}
              </div>
            </NameOfTariffAndPrice>
            {t(`rate.${i18keyRateIndex}.benefits`, { returnObjects: true }).map(
              (elm) => (
                <Benefit key={elm.text}>
                  <Mark />
                  <BenefitText isRtl={isRtl}>
                    <Trans>{t(elm.text)}</Trans>
                  </BenefitText>
                </Benefit>
              )
            )}
          </Selected>
        </ErrorPayStyled>
      </Container>
      <Container
        styles={{
          background: "#F5F5F5",
          padding: "34px 0px",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            description={t("enterEmail")}
            placeholder={t("yourEmail")}
            name="email"
            isError={Boolean(errors.email)}
            formRef={register}
          />
          <WrapperCheckbox>
            <Checkbox
              isError={Boolean(errors.agree)}
              name="agree"
              formRef={register}
              isRtl={isRtl}
              language={language}
            />
          </WrapperCheckbox>

          <Button
            renderLike="button"
            type="submit"
            styles={{ width: "100%", maxWidth: "initial" }}
            text={t("goToPay")}
            disabled={!(agreeWatch && validateEmail(emailWatch)) || isLoading}
          />
        </form>
      </Container>
    </>
  );
};

export default SelectedTariff;
