import { useLocation } from "react-router-dom";
import { Sprint } from "./Sprint";
import { Subscription } from "./Subscription";

const SuccessPay = ({ marafon, price }) => {
  const location = useLocation();
  const state = location.state;

  if (marafon) {
    return <Subscription state={state}  price={price}/>;
  }

  return <Sprint state={state} price={price} />;
};

export default SuccessPay;
