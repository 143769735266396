import styled from "styled-components";

export const ButtonStyled = styled.div`
  border-radius: 8px;
  background-color: #f3a614;
  box-shadow: 0 4px 0 0 #d98f00;
  line-height: 66px;
  width: 100%;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  max-width: 270px;
  font-weight: 800;
  border: none;
  outline: none;
  text-decoration: none;

  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  transition: all 0.2s ease-out;

  :active {
    background-color: #d98f00;
    box-shadow: 0 0 0 0 #d98f00;
  }
`;

export const ButtonSecondaryStyled = styled.div`
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid #ffffff;
  line-height: 66px;
  width: 100%;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  max-width: 270px;
  text-decoration: none;
  font-weight: 800;

  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
`;
