import { useState } from "react";
import {
  AnswerQuestionStyled,
  Title,
  Answer,
  TitleText,
  TitlePlus,
} from "./style";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import { Amplitude } from "@amplitude/react-amplitude";

export const AnswerQuestion = ({ question, answer, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <AnswerQuestionStyled>
      <Amplitude>
        {({ logEvent }) => (
          <Title
            onClick={() => {
              if (!isOpen) {
                logEvent("landing_tap_faq", { number: index });
              }
              setIsOpen(!isOpen);
            }}
          >
            <TitleText>{question}</TitleText>
            <TitlePlus isOpen={isOpen} />
          </Title>
        )}
      </Amplitude>
      <SlideDown>
        {isOpen ? (
          <Answer dangerouslySetInnerHTML={{ __html: answer }} />
        ) : null}
      </SlideDown>
    </AnswerQuestionStyled>
  );
};
