import { InputStyled, EmailDescription, EmailInput } from "./style";
import NumberFormat from "react-number-format";
import { Controller } from "react-hook-form";
export const Input = ({
  description,
  placeholder,
  formRef,
  name,
  isError,
  mask,
  control,
}) => {
  if (mask) {
    return (
      <InputStyled>
        <EmailDescription>{description}</EmailDescription>
        <Controller
          as={
            <NumberFormat
              customInput={EmailInput}
              placeholder={placeholder}
              format={mask}
              isError={isError}
            />
          }
          name={name}
          control={control}
        />
      </InputStyled>
    );
  }
  return (
    <InputStyled>
      <EmailDescription>{description}</EmailDescription>
      <EmailInput
        isError={isError}
        ref={formRef}
        name={name}
        placeholder={placeholder}
        type="email"
      />
    </InputStyled>
  );
};
