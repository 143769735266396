import { useTranslation } from "react-i18next";
import {
  AboutBrightStyled,
  AboutBrightElm,
  AboutBrightElmIcon,
  AboutBrightElmText,
} from "./style";
import download from "../../assets/download.svg";
import earth from "../../assets/earth.svg";
import cup from "../../assets/cup.svg";
import glass from "../../assets/glass.svg";

export const AboutBright = () => {
  const { t } = useTranslation("landing");

  const list = [
    { icon: download, text: t("downloads") },
    { icon: earth, text: t("languages") },
    { icon: cup, text: t("top5") },
    { icon: glass, text: t("scientificMethod") },
  ];

  return (
    <AboutBrightStyled>
      {list.map((elm) => (
        <AboutBrightElm key={elm.text}>
          <AboutBrightElmIcon src={elm.icon} />
          <AboutBrightElmText>{elm.text}</AboutBrightElmText>
        </AboutBrightElm>
      ))}
    </AboutBrightStyled>
  );
};
