import styled from "styled-components";

export const InputStyled = styled.div``;

export const EmailDescription = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 6px;
  color: #262626;
`;

export const EmailInput = styled.input`
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid ${({ isError }) => (isError ? "#e8573e" : "#d5d5d5")};
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  width: 100%;
  font-size: 18px;
  line-height: 25px;

  ::placeholder {
    font-size: 18px;
    line-height: 25px;
    color: ${({ isError }) => (isError ? "#e8573e" : "rgba(0, 0, 0, 0.2)")};
  }
`;
