import styled from "styled-components";
import {
  Dot,
  Dots,
  PrevSlideButton,
  NextSlideButton,
} from "../TrainingSection/style";

export const SliderWrapper = styled.div`
  margin: 0 -32px;
`;

export const FeedbackStyled = styled.div`
  background: #f1f2f7;
  border-radius: 8px;
  padding: 13px 20px;
  box-sizing: border-box;
  margin: 0 25px;
  width: 325px !important;
`;

export const FeedbackHeader = styled.div``;

export const FeedbackHeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FeedbackTitle = styled.div`
  width: 55%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #262626;
`;

export const FeedbackDate = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #262626;
  opacity: 0.4;
`;

export const FeedbackHeaderBottom = styled(FeedbackHeaderTop)``;

export const FeedbackUserName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #262626;
  text-align: right;
  opacity: 0.4;
`;

export const FeedbackText = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #09080d;
  margin-top: 9px;
`;

export const FeedbackContainer = styled.div``;

export const DotsStyled = styled(Dots)`
  margin-left: 8px;
  margin-right: auto;
  justify-content: inherit;
`;

export const DotStyled = styled(Dot)`
  width: 12px;
  height: 12px;
  background: ${({ isActive }) =>
    isActive ? "#7F7F84" : "rgba(0, 0, 0, 0.1)"};
  margin-left: 12px;
`;

export const PrevSlideButtonStyled = styled(PrevSlideButton)`
  width: 48px;
  height: 48px;
  margin-right: 16px;
`;

export const NextSlideButtonStyled = styled(NextSlideButton)`
  width: 48px;
  height: 48px;
`;
