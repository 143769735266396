import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Rating } from "../../assets/rate.svg";
import Slider from "react-slick";
import {
  FeedbackStyled,
  FeedbackHeader,
  FeedbackHeaderTop,
  FeedbackTitle,
  FeedbackDate,
  FeedbackHeaderBottom,
  FeedbackUserName,
  FeedbackText,
  FeedbackContainer,
  SliderWrapper,
} from "./style";

import {
  Controls,
  PrevSlideButton,
  Dots,
  Dot,
  NextSlideButton,
} from "../TrainingSection/style";
import { ArrowImg } from "../../style";
import arrow from "../../assets/arrowDark.svg";
import { Amplitude } from "@amplitude/react-amplitude";

export const FeedbackSlider = ({ isRtl }) => {
  const { t } = useTranslation("landing");
  const feedbackList = t("feedbacks", { returnObjects: true });

  const [currentSlider, setCurrentSlider] = useState(0);

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    centerMode: false,
    beforeChange: (_, newIndex) => setCurrentSlider(newIndex),
  };

  const sliderRef = useRef();

  useEffect(() => {
    sliderRef.current.slickGoTo(currentSlider);
  }, [currentSlider]);

  const prevSlider = () => {
    sliderRef.current.slickPrev();
  };

  const nextSlider = () => {
    sliderRef.current.slickNext();
  };

  return (
    <FeedbackContainer>
      <SliderWrapper>
        <Slider ref={sliderRef} {...settings}>
          {feedbackList.map((feedback) => (
            <FeedbackStyled key={feedback.feedback}>
              <FeedbackHeader>
                <FeedbackHeaderTop>
                  <FeedbackTitle>{feedback.title}</FeedbackTitle>
                  <FeedbackDate>{feedback.date}</FeedbackDate>
                </FeedbackHeaderTop>
                <FeedbackHeaderBottom>
                  <Rating />
                  <FeedbackUserName>{feedback.userName}</FeedbackUserName>
                </FeedbackHeaderBottom>
              </FeedbackHeader>
              <FeedbackText>{feedback.feedback}</FeedbackText>
            </FeedbackStyled>
          ))}
        </Slider>
      </SliderWrapper>
      <Controls style={{ marginTop: 32 }}>
        <Amplitude>
          {({ logEvent }) => (
            <PrevSlideButton
              style={{ visibility: currentSlider === 0 ? "hidden" : "visible" }}
              isRtl={isRtl}
              onClick={() => {
                logEvent("landing_tap_user_reviews");
                prevSlider();
              }}
            >
              <ArrowImg src={arrow} />
            </PrevSlideButton>
          )}
        </Amplitude>
        <Dots>
          {feedbackList.map((_, key) => (
            <Amplitude key={`sliderImgsDot${key}`}>
              {({ logEvent }) => (
                <Dot
                  isActive={currentSlider === key}
                  onClick={() => {
                    logEvent("landing_tap_user_reviews");
                    setCurrentSlider(key);
                  }}
                />
              )}
            </Amplitude>
          ))}
        </Dots>
        <Amplitude>
          {({ logEvent }) => (
            <NextSlideButton
              style={{
                visibility:
                  currentSlider === feedbackList.length - 1
                    ? "hidden"
                    : "visible",
              }}
              isRtl={!isRtl}
              onClick={() => {
                logEvent("landing_tap_user_reviews");
                nextSlider();
              }}
            >
              <ArrowImg src={arrow} />
            </NextSlideButton>
          )}
        </Amplitude>
      </Controls>
    </FeedbackContainer>
  );
};
