import styled from "styled-components";

export const AboutBrightStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const AboutBrightElm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 46%;
  margin-bottom: 35px;

  :nth-child(3),
  :nth-child(4) {
    margin-bottom: 0;
  }
`;

export const AboutBrightElmIcon = styled.img`
  width: 32px;
  height: 32px;
`;

export const AboutBrightElmText = styled.div`
  font-size: 14px;
  color: #fff;
  text-align: center;
  white-space: pre-line;
  margin-top: 10px;
  line-height: 19px;
`;
