import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 70px;
  > p {
    font-family: Manrope, sans-serif;
    color: #262626;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 400;
  }

  a {
    color: #00a1f0;
    text-decoration: none;
  }

  h1 {
    margin-top: 32px;
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 700;
    text-transform: uppercase;
  }

  h2 {
    margin-top: 32px;
    margin-bottom: 16px;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 700;
  }

  h3 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 700;
  }

  h4 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-family: "Manrope";
    font-size: 20px;
    line-height: 1.2;
    font-weight: 700;
  }
`;

export const FlyingButton = styled.div`
  position: fixed;
  bottom: 10px;
  z-index: 10;
  width: 311px;
  left: 50%;
  transform: translate(-50%);
`;
