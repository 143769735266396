import { TrapeziodContainer, TrapezoidSyled, TrapezoidText } from "./style";

export const Trapezoid = ({
  text = "no text",
  width = 160,
  traingleHeight = 8,
  triangleWidth = 10,
}) => {
  return (
    <TrapeziodContainer>
      <TrapezoidSyled
        width={width}
        traingleHeight={traingleHeight}
        triangleWidth={triangleWidth}
      />
      <TrapezoidText>{text}</TrapezoidText>
    </TrapeziodContainer>
  );
};
