import { useTranslation } from "react-i18next";
import { Container } from "../../components/Container";
import { ReactComponent as Close } from "./icons/close.svg";
import { ReactComponent as Man } from "./icons/man.svg";
import {
  ErrorPayStyled,
  Title,
  Top,
  WrapperMan,
  OopsMessage,
  WeWorking,
} from "./style";
import { useHistory } from "react-router-dom";
import { rtlDetect } from "../../libs/isRtl";
import { useEffect } from "react";
import { Button } from "../../components/Button/Button";
import amplitude from "amplitude-js";

const ErrorPay = () => {
  const { t } = useTranslation("landing");
  const history = useHistory();
  const isRtl = rtlDetect();

  useEffect(() => {
    document.title = t("titleError");
  }, [t]);

  useEffect(() => {
    amplitude.getInstance().logEvent("landing_error_screen");
  }, []);

  return (
    <Container
      styles={{
        padding: "18px 0",
        background: "#fff",
      }}
    >
      <ErrorPayStyled>
        <Top onClick={() => history.goBack()}>
          <Close />
          <Title isRtl={isRtl}>{t("errorPayTitle")}</Title>
        </Top>
        <WrapperMan>
          <Man />
        </WrapperMan>
        <OopsMessage>{t("errorPayErrorText")}</OopsMessage>
        <WeWorking>{t("errorPayWeWorkink")}</WeWorking>
        <Button
          styles={{
            width: "100%",
            maxWidth: "initial",
            background: "#27B535",
            boxShadow: "0px 4px 0px #209B2C",
            marginTop: 27,
          }}
          onClick={() => {
            amplitude.getInstance().logEvent("landing_error_retry");
            history.goBack();
          }}
          text={t("payAgain")}
        />
      </ErrorPayStyled>
    </Container>
  );
};

export default ErrorPay;
