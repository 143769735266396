import { useTranslation } from "react-i18next";
import { Container } from "../../components/Container";
import { Wrapper, FlyingButton } from "./style";
import { Button } from "../../components/Button/Button";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const Terms = () => {
  const { t } = useTranslation("landing");
  const history = useHistory();

  useEffect(() => {
    document.title = t('titleTerms')
    window.scrollTo({ top: 0 });
  }, [t]);

  return (
    <>
      <Container
        styles={{
          padding: "18px 0",
          background: "#fff",
          overflow: "scroll",
        }}
      >
        <Wrapper>
          <h1>Terms of Use</h1>
          <p>
            IMPORTANT! These termsof use govern your use of this site, which is
            provided by our company. Byaccessing this site, you are indicating
            your acknowledgment and acceptance ofthese terms of use. These terms
            of use are subject to change by our company atany time at our
            discretion. Your use of this site after such changes areimplemented
            constitutes your acknowledgment and acceptance of the changes.Please
            consult these terms of use regularly.
          </p>
          <h2>
            <strong>Access to this site:</strong>
          </h2>
          <p>
            You agree to use thissite only for the lawful purposes described in
            more detail in the “Restrictions on Use” section below. You agree
            not to take any action that might compromise the security of the
            site, render the site inaccessible to others or otherwise
            causedamage to the site or the Content. You agree not to add to,
            subtract from, orotherwise modify the Content, or to attempt to
            access any Content that is notintended for you. You agree not to use
            the site in any manner that mightinterfere with the rights of third
            parties. To access this site or some of theresources it has to
            offer, you may be asked to provide certain registrationdetails or
            other information. It is a condition of your use of this site
            thatall the information you provide on this site will be correct,
            current, andcomplete. If our Company believes the information you
            provide is not correct,current, or complete, we have the right to
            refuse you access to this site orany of its resources, and to
            terminate or suspend your access at any time,without notice.
          </p>
          <h2>
            <strong>Restrictions on use:</strong>
          </h2>
          <p>
            This site is providedsolely for non-commercial, personal use, and/or
            so that you may learn about ourCompany and the services we provide.
            You may not use this site for any otherpurpose, including any
            commercial purpose, without our Company’s express prior written
            consent. For example, you may not (andmay not authorize any other
            party to) (i) co-brand this site, or (ii) framethis site, or (iii)
            hyperlink to this site, without the express prior writtenpermission
            of an authorized representative of our Company. For purposes ofthese
            Terms of Use, “co-branding” means to display a name, logo,
            trademark, or other means of attribution or identification of any
            party in such a manner as is reasonably likely to give a user the
            impression that such other party has theright to display, publish,
            or distribute this site or Content accessible withinthis site. You
            agree to cooperate with our Company in causing any
            unauthorizedco-branding, framing or hyper-linking immediately to
            cease.
          </p>
          <h2>
            <strong>Proprietary information:</strong>
          </h2>
          <h3>Trademarks</h3>
          <p>
            The material and Contentaccessible from this site, and any other
            site owned, operated, licensed, orcontrolled by our Company is the
            proprietary information of our Company or theparty that provided the
            Content to our Company, and our Company or the partythat provided
            the Content to our Company retains all right, title, and interestin
            the Content. Accordingly, the Content may not be copied,
            distributed,republished, uploaded, posted, or transmitted in any way
            without the priorwritten consent of our Company, or unless
            authorized in writing elsewhere onour site, except that you may
            print out a copy of the Content solely for yourpersonal use. You are
            prohibited from using any of the marks or logos appearingthroughout
            the site without the express written permission of our Company.
          </p>
          <h3>Copyrights</h3>
          <p>
            This site and itsContent are protected by copyright laws and belong
            to the Company or itspartners, affiliates, contributors or third
            parties. The copyrights for theContent are owned by the Company or
            other copyright owners who have authorizedtheir use on this site.
            You may download and reprint Content fornon-commercial, non-public,
            personal use only (If you are browsing this site asan employee or
            member of any business or organization, you may download andre-print
            Content only for educational or other non-commercial purposes
            withinyour business or organization, except as otherwise permitted
            by the Company,for example in certain password-restricted areas of
            the site). You may notmanipulate or alter in any way images or other
            Content on the site.
          </p>
          <h3>Hyperlinks</h3>
          <p>
            This site may behyper-linked to other sites which are not maintained
            by, or related to, ourCompany. The inclusion of any hyperlink to a
            third-party site does not implyendorsement, sponsorship or
            recommendation by our Company of that site.Hyperlinks to such sites
            are provided as a service to users and are notsponsored by or
            affiliated with this site or our Company. Our Company has
            notreviewed any or all of such sites and is not responsible for the
            content ofthose sites. Our Company also makes no representations
            about the availabilityof hyperlinked sites. Hyperlinks are to be
            accessed at the user’s own risk, and our Company makes no
            representations or warranties about thecontent, completeness or
            accuracy of these hyperlinks or the sites hyperlinkedto this site.
            If you hyperlink to a site, please be aware that you will leaveour
            Company’s web site and will become subject to the rules and
            conditions of the linked site(s). Werecommend that you make yourself
            aware of the Terms of Use of any sites youlink to from our Company’s
            site.
          </p>
          <h3>Downloading Material</h3>
          <p>
            You understand that ourCompany cannot and does not guarantee or
            warrant that files available fordownloading from the Internet will
            be free of viruses, worms, Trojan horses orother code that may
            manifest contaminating or destructive properties. You areresponsible
            for implementing sufficient procedures and checkpoints to
            satisfyyour particular requirements for accuracy of data input and
            output, and formaintaining a means external to this site for the
            reconstruction of any lostdata. Our Company does not assume any
            responsibility or risk for your use ofthe Internet.
          </p>
          <h3>Disclaimer</h3>
          <p>
            THE INFORMATION, PRODUCTS AND SERVICES OFFEREDON OR THROUGH THE SITE
            AND BY COMPANY AND ANY THIRD-PARTY SITES ARE PROVIDED “AS IS” AND
            WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. TO THE
            FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE DISCLAIM
            ALL WARRANTIES, EXPRESS OR IMPLIED,INCLUDING, BUT NOT LIMITED TO,
            IMPLIED WARRANTIES OF MERCHANTABILITY ANDFITNESS FOR A PARTICULAR
            PURPOSE. WE DO NOT WARRANT THAT THE SITE OR ANY OF ITSFUNCTIONS WILL
            BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED,OR
            THAT ANY PART OF THIS SITE, INCLUDING BULLETIN BOARDS, OR THE
            SERVERS THATMAKE IT AVAILABLE, ARE FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS. WE DO NOTWARRANT OR MAKE ANY REPRESENTATIONS REGARDING
            THE USE OR THE RESULTS OF THE USEOF THE SITE OR MATERIALS ON THIS
            SITE OR ON THIRD-PARTY SITES IN TERMS OF THEIRCORRECTNESS, ACCURACY,
            TIMELINESS, RELIABILITY OR OTHERWISE.
          </p>
          <h2>
            <strong>With Regards To Financial Content On The Site:</strong>
          </h2>
          <p>
            NEITHER COMPANY NOR ITSOWNERS, OFFICERS, DIRECTORS, EMPLOYEES,
            SUBSIDIARIES, AFFILIATES, LICENSORS,SERVICE PROVIDERS, CONTENT
            PROVIDERS AND AGENTS ARE FINANCIAL ADVISERS ANDNOTHING CONTAINED ON
            THE SITE IS INTENDED TO BE OR TO BE CONSTRUED AS FINANCIALADVICE.
            COMPANY IS NOT AN INVESTMENT ADVISORY SERVICE, IS NOT AN
            INVESTMENTADVISER, AND DOES NOT PROVIDE PERSONALIZED FINANCIAL
            ADVICE OR ACT AS AFINANCIAL ADVISOR. THE SITE EXISTS FOR EDUCATIONAL
            PURPOSES ONLY, AND THEMATERIALS AND INFORMATION CONTAINED HEREIN ARE
            FOR GENERAL INFORMATIONALPURPOSES ONLY. THE EDUCATION AND
            INFORMATION PRESENTED HEREIN IS INTENDED FOR AGENERAL AUDIENCE AND
            DOES NOT PURPORT TO BE, NOR SHOULD IT BE CONSTRUED AS,SPECIFIC
            ADVICE TAILORED TO ANY INDIVIDUAL. YOU ARE ENCOURAGED TO DISCUSS
            ANYOPPORTUNITIES WITH YOUR ATTORNEY, ACCOUNTANT, FINANCIAL
            PROFESSIONAL OR OTHERADVISOR.
          </p>
          <h3>Limitation on liability</h3>
          <p>
            Company, itssubsidiaries, affiliates, licensors, service providers,
            content providers,employees, agents, officers, and directors will
            not be liable for anyincidental, direct, indirect, punitive, actual,
            consequential, special,exemplary, or other damages, including loss
            of revenue or income, pain andsuffering, emotional distress, or
            similar damages, even if company has beenadvised of the possibility
            of such damages. in no event will the collectiveliability of company
            and its subsidiaries, affiliates, licensors, serviceproviders,
            content providers, employees, agents, officers, and directors, toany
            party (regardless of the form of action, whether in contract, tort,
            orotherwise) exceed the greater of $100 or the amount you have paid
            to companyfor the applicable content, product or service out of
            which liability.
          </p>
          <h3>Indemnity</h3>
          <p>
            You will indemnify andhold Company, its subsidiaries, affiliates,
            licensors, content providers,service providers, employees, agents,
            officers, directors, and contractors(hereinafter known as the
            “Indemnified Parties”) harmless from any breach of these Terms of
            Use by you, including any use of Content other than as expressly
            authorized in these Terms of Use. You agree thatthe Indemnified
            Parties will have no liability in connection with any suchbreach or
            unauthorized use, and you agree to indemnify any and all
            resultingloss, damages, judgments, awards, costs, expenses, and
            attorney’s fees of the Indemnified Parties in connection therewith.
            You will also indemnify and hold the Indemnified Parties harmless
            from and against any claims brought by third parties arising out of
            your use of the information accessed from this site.
          </p>
          <h3>Dispute Resolution</h3>
          <p>
            These Terms of Use shallbe governed by and construed in accordance
            with the English law, If anyprovision of this agreement shall be
            unlawful, void or for any reasonunenforceable, then that provision
            shall be deemed severable from thisagreement and shall not affect
            the validity and enforceability of any remainingprovisions.
          </p>
          <h3>Class Action Waiver</h3>
          <p>
            You may only resolvedisputes with us on an individual basis, and may
            not bring a claim as aplaintiff or a class member in a class,
            consolidated, or representative action.Class arbitrations, class
            actions, private attorney general actions, andconsolidation with
            other arbitrations aren’t allowed. The arbitrator may not
            consolidate more than one person’s claims, and may not otherwise
            preside over any form of a class or representative proceeding or
            claims (such as a class action,consolidated action or private
            attorney general action) unless all relevantparties specifically
            agree to do so following initiation of the arbitration.
          </p>
          <h3>Severability</h3>
          <p>
            If any clause within theTerms of Service (other than the Class
            Action Waiver clause above) is found tobe illegal or unenforceable,
            that clause will be severed from the Terms ofService, and the
            remainder of these Terms of Service will be given full forceand
            effect. If the Class Action Waiver clause is found to be illegal
            orunenforceable, the dispute will be decided by a court.
          </p>
          <h3>Miscellaneous</h3>
          <p>
            These Terms of Useconstitute the entire agreement among the parties
            relating to this subjectmatter. Notwithstanding the foregoing, any
            additional terms and conditions,such as our Privacy Policy and other
            disclosures, on this site will govern theitems to which they
            pertain. Company may revise these Terms of Use at any timeby
            updating this posting.
          </p>
          <p>‍</p>
          <p>LANGUAGE APPS LIMITED</p>
          <p>
            ROOM 913, &nbsp;9F, HOLLYWOOD PLAZA, 610 NATHAN ROAD, MONG KOK,
            KOWLOON, Hong Kong <br />
          </p>
          <div
            style={{
              textAlign: "center",
              color: "rgba(38, 38, 38, 0.48)",
              padding: "24px 0",
              fontSize: 14,
            }}
          >
            © 2020, Language Apps LTD
          </div>
        </Wrapper>
        <FlyingButton>
          <Button
            onClick={() => history.goBack()}
            styles={{ maxWidth: 311, width: "100%" }}
            text={t("close")}
          />
        </FlyingButton>
      </Container>
    </>
  );
};

export default Terms;
