import { Container } from "../Container/Container";
import { BurgerStyled, BurgerWrapper } from "./style";

export const Burger = ({ isBlack, onClick, isOpenMenu, isRtl }) => {
  return (
    <Container
      styles={{
        position: "fixed",
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
        width: "100%",
        zIndex: 100,
      }}
    >
      <BurgerWrapper isRtl={isRtl} onClick={onClick}>
        <BurgerStyled isRtl={isRtl} isOpenMenu={isOpenMenu} isBlack={isBlack} />
      </BurgerWrapper>
    </Container>
  );
};
