import styled from "styled-components";

export const MenuStyled = styled.div`
  position: fixed;
  top: 0;
  transform: ${({ isOpen }) =>
    isOpen ? "translateY(0)" : "translateY(-100%)"};
  padding: 19px 24px 38px;
  background-color: #ffffff;
  border-radius: 0px 0px 16px 16px;
  box-sizing: border-box;
  width: 100%;
  z-index: 99;
  transition: transform 0.3s ease-out;

  ::before {
    visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    transition: opacity 0.3s ease-out;
    position: absolute;
    top: 100%;
    height: 100vh;
    left: 0;
    right: 0;
    content: "";
    background: rgba(38, 38, 38, 0.6);
  }
`;

export const Title = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 120%;
  color: #262626;
  margin-bottom: 58px;
`;

export const Link = styled.a`
  margin-bottom: 42px;
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const LinkImg = styled.img`
  flex-shrink: 0;
  width: 21px;
  height: 21px;
`;

export const LinkText = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #262626;
  ${({ isRtl }) => (isRtl ? "margin-right: 18px" : "margin-left: 18px")};
`;

export const MenuContainer = styled.div`
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
`;
