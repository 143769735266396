import styled, { css } from "styled-components";

export const BurgerWrapper = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isRtl }) => (isRtl ? "margin-right: auto" : "margin-left: auto")};
`;

export const BurgerStyled = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: ${({ isRtl }) => (isRtl ? "flex-end" : "baseline")};
  flex-direction: column;
  cursor: pointer;

  ::before,
  ::after {
    transition: all 0.3s ease-out;
  }

  ${({ isOpenMenu }) => {
    if (isOpenMenu) {
      return css`
        ::before,
        ::after {
          content: "";
          height: 3px;
          background-color: #bebebe;
        }

        ::before {
          width: 24px;
          transform: translateY(140%) rotate(45deg);
        }

        ::after {
          width: 24px;
          transform: translateY(-140%) rotate(-45deg);
        }
      `;
    }
    return css`
      ::before,
      ::after {
        content: "";
        height: 3px;
        background-color: ${({ isBlack }) => (isBlack ? "#262626" : "#fff")};
      }

      ::before {
        width: 24px;
      }

      ::after {
        width: 13px;
      }
    `;
  }}
`;
