import { useTranslation } from "react-i18next";
import { Container } from "../../components/Container";
import { Wrapper, FlyingButton } from "./style";
import { Button } from "../../components/Button/Button";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import i18n from "../../i18n";
import { dic } from "../../App";

const Policy = () => {
  const { t } = useTranslation("landing");
  const history = useHistory();
  const language = dic[i18n.language.split("-")[0]] || "ru";

  useEffect(() => {
    document.title = t("titlePrivacy");
    window.scrollTo({ top: 0 });
  }, [t]);

  return (
    <>
      <Container
        styles={{
          padding: "18px 0",
          background: "#fff",
          overflow: "scroll",
        }}
      >
        <Wrapper>
          <h1>Privacy Policy</h1>
          <p>
            The following Privacy Policy governs the online information
            collection practices of Bright and its associated global entities
            (“Company,” “we” or “us”). Specifically, it outlines the types of
            information that we gather about you while you are using{" "}
            <a href={`https://landing.engbright.com/home/${language}-pay`}>
              {`https://landing.engbright.com/home/${language}-pay`}
            </a>{" "}
            and other websites operated by Company (collectively, the “Site”),
            and the ways in which we use this information. This Privacy Policy,
            including our children’s privacy statement, applies primarily to
            information which we collect online; however, the portion of this
            Privacy Policy that relates to the European Union’s General Data
            Privacy Regulation may apply to some of the data that you provide to
            us offline and/or through other means, as well (for example, at a
            live event, via telephone, or through the mail).
          </p>
          <p>
            We have created this Privacy Policy to demonstrate our firm
            commitment to privacy and security. This Privacy Policy describes
            how our Company collects information from all end users of our
            Internet services (the “Services”), including those who access some
            of our Services but do not have accounts (“Visitors”) and those who
            may purchase Products and/or pay a monthly service fee to subscribe
            to the Service (“Members”).
          </p>
          <p>
            Please read this Privacy Policy carefully. By visiting and using the
            Site, you agree that your use of our Site, and any dispute over
            privacy, is governed by this Privacy Policy. In an effort to comply
            with changes in technology, and the adoption of new regulations and
            laws, we may need to change our Policy at some point in the future,
            in which case we’ll post the changes to this Privacy Policy on this
            website and update the Effective Date of the policy to reflect the
            date of the changes. By continuing to use the Site after we post any
            such changes, you accept the Privacy Policy as modified.
          </p>
          <h2>Introduction</h2>
          <p>
            We may collect and store personal or other information that you
            voluntarily supply to us online while using the Site (e.g., while on
            the Site or in responding via email to a feature provided on the
            Site). The Site only contacts individuals who specifically request
            that we do so or in the event that they have signed up to receive
            our messaging, attended one of our events, or have purchased one of
            our products. The Site collects personally identifying information
            from our users during online registration and online purchasing.
            Generally, this information includes name and email address for
            registration or opt-in purposes and name, postal address, and credit
            card information when registering for our events or purchasing our
            products. All of this information is provided to us by you.
          </p>
          <p>
            We also collect and store information that is generated
            automatically as you navigate online through the Site. For example,
            we may collect information about your computer’s connection to the
            Internet, which allows us, among other things, to improve the
            delivery of our web pages to you and to measure traffic on the Site.
            If you have accessed our site via a social media platform, such as
            Facebook, we may collect information related to your social media
            account, such as your handle or identifier on that platform. We also
            may use a standard feature found in browser software called a
            “cookie” to enhance your experience with the Site, and web beacons,
            to access cookies, count users who visit the Site, or open
            HTML-formatted email messages.
          </p>
          <p>
            We use the information we collect from you while you are using the
            Site in a variety of ways, including using the information to
            customize features; advertising that appear on the Site; and, making
            other offers available to you via email, direct mail or otherwise.
            We also may provide your information to third parties, such as
            service providers, contractors and third-party publishers and
            advertisers for a variety of purposes. Unless you inform us in
            accordance with the process described below, we reserve the right to
            use, and to disclose to third parties, all of the information
            collected from and about you while you are using the Site in any way
            and for any purpose, such as to enable us or a third party to
            provide you with information about products and services.
          </p>
          <p>
            Please keep in mind that whenever you voluntarily make your personal
            information available for viewing by third parties online – for
            example on message boards, web logs, through email, or in chat areas
            – that information can be seen, collected and used by others besides
            us. We cannot be responsible for any unauthorized third-party use of
            such information.
          </p>
          <p>
            Some of our third-party advertisers and ad servers that place and
            present advertising on the Site also may collect information from
            you via cookies, web beacons or similar technologies. These
            third-party advertisers and ad servers may use the information they
            collect to help present their advertisements, to help measure and
            research the advertisements’ effectiveness, or for other purposes.
            The use and collection of your information by these third-party
            advertisers and ad servers is governed by the relevant third-party’s
            privacy policy and is not covered by our Privacy Policy. Indeed, the
            privacy policies of these third-party advertisers and ad servers may
            be different from ours. If you have any concerns about a third
            party’s use of cookies or web beacons or use of your information,
            you should visit that party’s website and review its privacy policy.
          </p>
          <p>
            The Site also includes links to other websites and provides access
            to products and services offered by third parties, whose privacy
            policies we do not control. When you access another website or
            purchase third-party products or services through the Site, use of
            any information you provide is governed by the privacy policy of the
            operator of the site you are visiting or the provider of such
            products or services.
          </p>
          <p>
            Please also note that as our business grows, we may buy or sell
            various assets. In the unlikely event that we sell some or all of
            our assets, or one or more of our websites is acquired by another
            company, information about our users may be among the transferred
            assets.
          </p>
          <h2>Personal Information Our Company Collects And How It Is Used:</h2>
          <h3>Introduction</h3>
          <p>
            Members may be asked to provide certain personal information when
            they sign up for our Products or Services including name, address,
            telephone number, billing information (such as a credit card
            number), and the type of personal computer being used to access the
            Services. The personal information collected from Members during the
            registration process (or at any other time) is used primarily to
            provide a customized experience while using our Products and
            Services. Your information will never be disclosed, traded, licensed
            or sold to any third party. However, we may make limited disclosure
            of personal information under the specific circumstances described
            below.
          </p>
          <p>
            The information you provide to us will never be disclosed, traded,
            licensed or sold to a third party. However, there are specific
            instances, described below, where your information could be provided
            to a known third party.
          </p>
          <h3>The Types of Information We Collect and Store:</h3>
          <h4>
            Some of the information we may collect about you and store in
            connection with the provision and fulfillment of our services to you
            may include:
          </h4>
          <p>• Name</p>
          <p>• Email Address</p>
          <p>• Mailing Address</p>
          <p>• Billing Address</p>
          <p>• PayPal Email Address</p>
          <p>• Masked Credit Card Information (for refunds)</p>
          <p>• Age</p>
          <p>• Gender</p>
          <p>• Marital Status</p>
          <p>• Religion</p>
          <p>• Income Range</p>
          <p>• Education</p>
          <p>• Profession</p>
          <p>• Parent Status</p>
          <p>• Other responses to surveys and questionnaires</p>
          <p>• Sign-up source</p>
          <p>• Date of account creation</p>
          <p>• Date of account updates</p>
          <p>• IP address</p>
          <p>• Time stamp</p>
          <p>• Device type</p>
          <p>• Operating system</p>
          <p>• Browser</p>
          <p>• Email open clicks</p>
          <p>• User ID</p>
          <p>• User behavior</p>
          <p>• Login history</p>
          <p>• Order details</p>
          <p>• Location</p>
          <p>• Facebook ID</p>
          <p>• Photo</p>
          <p>• Language</p>
          <p>• Country</p>
          <p>• Timezone</p>
          <p>• Birthday</p>
          <p>• NPS score</p>
          <p>• Your rating of our support</p>
          <p>• Telephone number</p>
          <p>• Telephone call recordings</p>
          <p>• Testimonials, with your authorization</p>
          <p>• Anonamyzed Facebook comments</p>
          <h3>
            When you use our Platform, we automatically collect information
            about you, including:
          </h3>
          <p>
            Date of account creation, Date of account updates, IP address,
            Device type, Time stamp, Operating system, Browser, Email open
            clicks, Login history, Order details, Location, Language, Country,
            Timezone, Sign-up source.
          </p>
          <p>
            Some of the information we may collect about you and store in
            connection with the provision and fulfillment of our services to you
            may include: Age, Gender, Marital Status, Religion, Income Range,
            Education, Profession, Parent Status, Birthday, Telephone number.
          </p>
          <h3>How We Use Your Personal Information:</h3>
          <h4>
            The above personal information may be used for the following
            purposes:
          </h4>
          <p>• To operate, improve, or promote our Service </p>
          <p>• To contact you </p>
          <p>• When you have opted in to receive email messaging </p>
          <p>
            • To respond to your email inquiries. Specifically, when Visitors or
            Members send email inquiries to us, the return email address is used
            to answer the email inquiry we receive. We do not use the return
            email address for any other purpose, or share it with third parties.{" "}
          </p>
          <p>• To monitor and analyze trends such as:</p>
          <p>• Purchase history</p>
          <p>• Sales reports</p>
          <p>• Behavior on the Site</p>
          <p>• Email clicks and opens</p>
          <p>• To market our products and services through:</p>
          <p>• Email marketing </p>
          <p>• Advertising, including retargeting via Google and Facebook </p>
          <p>• Notifications</p>
          <p>• Perform accounting, administrative and legal tasks </p>
          <h3>Who Has Access to Your Data Within Our Organization:</h3>
          <p>
            Within our organization, access to your data is limited to those
            persons who require access in order to provide you with the Products
            and Services you purchase from us, to contact you, and to respond to
            your inquiries, including requests for refund. Those staff members
            may be on teams such as: marketing, events, development, executive,
            customer support. Employees only have access to data that is
            relevant to their team, on a “need to know” basis.
          </p>
          <h3>
            Who We Share Your Data With Outside of Our Organization and Why:
          </h3>
          <h4>Processors</h4>
          <p>
            We may use service providers in connection with operating and
            improving the Site, to assist with certain functions, such as
            payment processing, email transmission, conducting surveys or
            contests, data hosting, managing our ads, and some aspects of our
            technical and customer support. We take measures to ensure that
            these service providers access, process, and store information about
            you only for the purposes we authorize, subject to confidentiality
            obligations, including through the execution of GDPR-compliant Data
            Privacy Agreements or Addenda, as applicable.
          </p>
          <p>
            We use 3rd-party solutions for marketing and analytics. This
            processing occurs on third party platforms on accounts owned by
            Company, including, but not limited to, Google Analytics, Appsflyer.
            This processing is subject to our Data Privacy Agreements or Addenda
            with these third parties, and their respective privacy policies.
          </p>
          <h4>Authorities</h4>
          <p>
            We may access, preserve, and disclose information about you to third
            parties, including the content of messages, if we believe disclosure
            is in accordance with, or required by, applicable law, regulation,
            legal process, or audits. We may also disclose information about you
            if we believe that your actions are inconsistent with our{" "}
            <a href={`https://landing.engbright.com/terms-of-use/${language}`}>
              {`https://landing.engbright.com/terms-of-use/${language}`}
            </a>{" "}
            or related guidelines and policies, or if necessary to protect the
            rights, property, or safety of, or prevent fraud or abuse of,
            Company or others.
          </p>
          <h3>Why We Store Information We Collect From You:</h3>
          <p>
            We retain certain information that we collect from you while you are
            a member on the Site, and in certain cases where you have deleted
            your account, for the following reasons:
          </p>
          <p>• So you can use our Site;</p>
          <p>
            • To ensure that we do not communicate with you if you have asked us
            not to;
          </p>
          <p>• To provide you with a refund, if entitled;</p>
          <p>
            • To better understand the traffic to our Site so that we can
            provide all members with the best possible experience;
          </p>
          <p>
            • To detect and prevent abuse of our Site, illegal activities and
            breaches of our Terms of Service; and
          </p>
          <p>
            • To comply with applicable legal, tax or accounting requirements.
          </p>
          <p>
            When we have no ongoing legitimate business need to process your
            information, we will either delete or anonymize it.
          </p>
          <h3>Third Party Data:</h3>
          <h4>Login From Integrations</h4>
          <p>
            For ease of use, you are also given the option to link your Bright
            account on the Site with your account on a third-party site such as,
            for example, Facebook. The third party site will authenticate your
            identity, will allow you to log into Bright platforms using the
            single social login buttons, and will share certain personal
            information that you allow us to collect. By signing-in using your
            third party identity provider account (“Third Party IdP”), you are
            authorizing Bright to collect, store, and use, in accordance with
            this Privacy Policy, any and all information that you have
            authorized the Third Party IdP to provide to Bright. Such
            information may include your first and last name, gender, location,
            username, e-mail address, picture or phone number.
          </p>
          <p>
            You will be given the option to link your Bright account with your
            social media account e.g. Facebook. When you choose to login with
            your social media account the social media site will: authenticate
            your identity, allow you to log into your Bright account using the
            single social login button, and provide personal information that
            you agree to share with us.
          </p>
          <p>
            Logging in this way means that you give Bright authorization to
            collect, store, and use your information in accordance with this
            Privacy Policy. Such information may include your first and last
            name, gender, location, username, email address, picture, or phone
            number.
          </p>
          <h4>Third Party Integration</h4>
          <p>
            If you do login through a third party account like Facebook, we
            share some information with them and they share some information
            with us but only for purposes of accessing your account. What is
            shared with us by the third party sites depends on their Privacy
            Policy. We would advise that you familiarize yourself with the
            privacy policy of the third party site to know what information is
            being shared by them.
          </p>
          <p>
            When you login to your Bright account with your social media account
            we also share some basic account information with your social media
            provider. What we share with the social media provider depends on
            their Privacy Policy. We advise you to check the Privacy Policy of
            the social media site to familiarize yourself with the information
            they request from us.
          </p>
          <h4>Affiliate Links</h4>
          <p>
            Occasionally you may have clicked on one of Bright’s affiliate links
            and signed up or bought something via that link. In this case, the
            person or organization in charge of the affiliate link will have the
            information that you have submitted. This may be your email address,
            name, address, or whatever else they asked you. If you have signed
            up for bought something from Bright via an affiliate link, please
            check the privacy policy on their site.
          </p>
          <h4>Online Shopping</h4>
          <p>
            If you order services or products directly from our Company we will
            use the personal information you provide only to process that order.
            We do not share this information with outside parties except to the
            extent necessary to complete that order.
          </p>
          <p>
            If you complete an order for some other company(ies) that may be
            linked to our Site, such as an online gift order sent directly to a
            recipient, you may be asked to provide information about the
            recipient, such as the recipient’s name, address, and phone number.
            Our company has no control over the third parties’ use of any
            personal information you provide when placing such an order. Please
            exercise care when doing so. We recommend that you familiarize
            yourself with the Privacy and Terms of Use policies of any other
            company(ies) whose web sites you may access from our site.
          </p>
          <h4>Cookies and Tracking Tools</h4>
          <p>
            We use cookies to help you make using our website easier, such as:
          </p>
          <p>• To remember your country and language preferences</p>
          <p>• To deliver information that matches your interests</p>
          <p>• To help us understand our audience and traffic patterns</p>
          <p>
            • To let you automatically log into programs and parts of our site
            that require membership
          </p>
          <p>
            • To manage and present site info displayed on our website that will
            be specific to you
          </p>
          <p>
            We also use Web Beacons to collect personal and non-personal data on
            how you use our site, such as how long did you visit our page, what
            web browser you’re using, what’s your operating system, and who’s
            your Internet service provider. In addition we also use Google
            Analytics data and the DoubleClick cookie to serve ads based on a
            user’s prior visits to our website. This data is collected from
            thousands of site visits and analyzed as a whole. This helps us
            build a better website to match our visitors’ needs.
          </p>
          <p>
            We may also place small “tracker gifs” or “beacons” on many of the
            pages on our website, in online advertising with third parties, and
            in our emails. We use these beacons, in connection with Cookies, to
            collect non-personal data on the usage of our site including but not
            limited to the date and time of the visit, the pages visited, the
            referring web page, the type of browser (e.g., Internet Explorer,
            NetScape), the type of operating system (e.g., Windows, Linux, or
            Mac), and the domain name of the visitor’s Internet service provider
            (e.g., AOL). This information is collected about thousands of site
            visits and analyzed as a whole. This information is useful in, for
            example, tracking the performance of our online advertising such as
            online banner ads and to determine where to place future advertising
            on other websites.
          </p>
          <p>
            Disabling Cookies and Beacons: If you are uncomfortable with the
            collection of such information through the use of Cookies and
            Beacons, we recommend disabling these features through your browser
            preferences, though please understand this will limit the
            performance and functionality of our Company’s website. Your browser
            documentation should provide specific procedures for disabling
            cookie and beacon support.
          </p>
          <p>
            You may also opt out of the DoubleClick cookie by visiting the
            Google advertising opt-out page:
            http://www.google.com/policies/privacy/ads/.
          </p>
          <p>
            Or you may opt out of Google Analytics by visiting the Google
            Analytics Opt-out page: http://tools.google.com/dlpage/gaoptout.
          </p>
          <p>Advertiser Disclosures</p>
          <h4>Google Analytics</h4>
          <p>
            We use Google Analytics Advertiser Features to optimize our
            business. Advertiser features include:
          </p>
          <p>• Remarketing with Google Analytics</p>
          <p>• Google Display Network Impression Reporting</p>
          <p>• DoubleClick Platform integrations</p>
          <p>• Google Analytics Demographics and Interest Reporting</p>
          <p>
            By enabling these Google Analytics Display features, we are required
            to notify our visitors by disclosing the use of these features and
            that we and third-party vendors use first-party cookies (such as the
            Google Analytics cookie) or other first-party identifiers, and
            third-party cookies (such as the DoubleClick cookie) or other
            third-party identifiers together to gather data about your
            activities on our Site. Among other uses, this allows us to contact
            you if you begin to fill out our check-out form but abandon it
            before completion with an email reminding you to complete your
            order. The “Remarketing” feature allows us to reach people who
            previously visited our Site, and match the right audience with the
            right advertising message.
          </p>
          <p>
            You can opt out of Google’s use of cookies by visiting Google’s ad
            settings.
          </p>
          <h4>Facebook</h4>
          <p>
            As advertisers on Facebook and through our Facebook page, we, (not
            Facebook) may collect content or information from a Facebook user
            and such information may be used in the same manner specified in
            this Privacy Policy. You consent to our collection of such
            information. We abide by Facebook’s Data Use Restrictions.
          </p>
          <p>
            • Any ad data collected, received or derived from our Facebook ad
            (“Facebook advertising data”) is only shared with someone acting on
            our behalf, such as our service provider. We are responsible for
            ensuring that our service providers protect any Facebook advertising
            data or any other information obtained from us, limit our use of all
            of that information, and keep it confidential and secure.
          </p>
          <p>
            • We do not use Facebook advertising data for any purpose (including
            retargeting, commingling data across multiple advertisers’
            campaigns, or allowing piggybacking or redirecting with tags),
            except on an aggregate and anonymous basis (unless authorized by
            Facebook) and only to assess the performance and effectiveness of
            our Facebook advertising campaigns.
          </p>
          <p>
            • We do not use Facebook advertising data, including the targeting
            criteria for a Facebook ad, to build, append to, edit, influence, or
            augment user profiles, including profiles associated with any mobile
            device identifier or other unique identifier that identifies any
            particular user, browser, computer or device.
          </p>
          <p>
            • We do not transfer any Facebook advertising data (including
            anonymous, aggregate, or derived data) to any ad network, ad
            exchange, data broker or other advertising or monetization related
            service.
          </p>
          <h2>Data Security and Data Privacy Regulation</h2>
          <p>Our company’s commitment to data security:</p>
          <p>
            We implement a variety of administrative, managerial, and technical
            security measures to help protect your personal information. Our
            Company has various internal control standards which relate
            specifically to the handling of personal information. These include
            certain controls to help safeguard the information we collect
            online. Our employees are trained to understand and comply with
            these controls and we communicate our Privacy Policy, practices and
            guidelines to our employees. However, while we strive to protect
            your personal information, you must also take steps to protect your
            information. We urge you to take every precaution to protect your
            personal information while you are on the Internet.
          </p>
          <p>
            Services and websites we sponsor have security measures in place to
            protect the loss, misuse, and alteration of the information under
            our control. While we make every effort to ensure the integrity and
            security of our network and systems, we cannot guarantee that our
            security measures will prevent third-party “hackers” from illegally
            obtaining this information. If we do discover a security breach
            affecting your data, every effort will be made to provide a
            notification within 72 hours of our team learning of the occurrence.
          </p>
          <p>General Data Privacy Regulation (GDPR)</p>
          <p>
            The GDPR takes effect on May 25, 2018, and is intended to protect
            the data of European Union (EU) citizens.
          </p>
          <p>
            If you are a resident of the European Economic Area (EEA), or are
            accessing this Site from within the EEA, you have certain rights
            with respect to your data. We respond to all requests that we
            receive from individuals who wish to exercise their data protection
            rights in accordance with applicable data protection laws. You can
            contact us by sending an email to{" "}
            <a href="http://support@engbright.com">support@engbright.com</a>
          </p>
          <p>
            Rights that you may have, depending on the country in which you
            live, include:
          </p>
          <p>
            • Accessing, correcting, updating, or requesting deletion of your
            information.
          </p>
          <p>
            • If you request that your account be deleted, you will lose access
            to all of our programs in which you are actively registered.
          </p>
          <p>
            • Objecting to processing of your information, asking us to restrict
            processing of your information, or requesting the portability of
            your information.
          </p>
          <p>
            • Opting out from receiving marketing communications that we send
            you at any time. You can exercise this right by selecting the
            “unsubscribe” or “opt-out” link in the marketing emails we send you.
            Additionally, you may update your email preferences by changing the
            settings in your account.
          </p>
          <p>
            • Withdrawing your consent at any time if we have collected and
            processed your information with your consent. Withdrawing your
            consent will not affect the lawfulness of any processing that we
            conducted prior to your withdrawal, nor will it affect processing of
            your information conducted in reliance on lawful processing grounds
            other than consent.
          </p>
          <p>
            • Complaining to a data protection authority about our collection
            and use of your information. For more information, please contact
            your local data protection authority. Contact details for data
            protection authorities in the European Union are available here.
          </p>
          <p>Children’s Privacy Statement</p>
          <p>
            This children’s privacy statement explains our practices with
            respect to the online collection and use of personal information
            from children under the age of thirteen, and provides important
            information regarding their rights under federal law with respect to
            such information.
          </p>
          <p>
            • This Site is not directed to children under the age of thirteen
            and we do NOT knowingly collect personally identifiable information
            from children under the age of thirteen as part of the Site. We
            screen users who wish to provide personal information in order to
            prevent users under the age of thirteen from providing such
            information. If we become aware that we have inadvertently received
            personally identifiable information from a user under the age of
            thirteen as part of the Site, we will delete such information from
            our records. If we change our practices in the future, we will
            obtain prior, verifiable parental consent before collecting any
            personally identifiable information from children under the age of
            thirteen as part of the Site.
          </p>
          <p>
            • Because we do not collect any personally identifiable information
            from children under the age of thirteen as part of the Site, we also
            do NOT knowingly distribute such information to third parties. We do
            NOT knowingly allow children under the age of thirteen to publicly
            post or otherwise distribute personally identifiable contact
            information through the Site.
          </p>
          <p>
            • Because we do not collect any personally identifiable information
            from children under the age of thirteen as part of the Site, we do
            NOT condition the participation of a child under thirteen in the
            Site’s online activities on providing personally identifiable
            information.
          </p>
          <p>Revisions to this policy</p>
          <p>
            Our Company reserves the right to revise, amend, or modify this
            policy, our{" "}
            <a href={`https://landing.engbright.com/terms-of-use/${language}`}>
              {`https://landing.engbright.com/terms-of-use/${language}`}
            </a>
            , and our other policies and agreements at any time and in any
            manner, by updating this posting.
          </p>
          <p>Where to direct questions about our privacy policy</p>
          <p>
            If you have any questions about this Privacy Policy or the practices
            described herein, you may contact us through the contact information
            provided on this website.{" "}
            <a href="http://support@engbright.com">support@engbright.com</a>
          </p>
          <div
            style={{
              textAlign: "center",
              color: "rgba(38, 38, 38, 0.48)",
              padding: "24px 0",
              fontSize: 14,
            }}
          >
            © 2020, Language Apps LTD
          </div>
        </Wrapper>
        <FlyingButton>
          <Button
            onClick={() => history.goBack()}
            styles={{ maxWidth: 311, width: "100%" }}
            text={t("close")}
          />
        </FlyingButton>
      </Container>
    </>
  );
};

export default Policy;
