import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import i18n from "./i18n";

import Landing from "./Landing";
import LandingEnglishVersion from "./LandingEnglishVersion";
import ErrorPay from "./pages/ErrorPay";
import ErrorPayDuringPay from "./pages/ErrorPayDuringPay";
import SelectedTariff from "./pages/SelectedTariff";
import SuccessPay from "./pages/SuccessPay";
import Terms from "./pages/Terms";
import Policy from "./pages/Terms/Policy";
import { rtlDetect } from "./libs/isRtl";
import amplitude from "amplitude-js";

export const dic = {
  ru: "ru",
  es: "es",
  zh: "zh-cn",
  ar: "ar",
  "en": "en",
  "en-GB": "en",
  "en-US": "en",
};

export const LOCAL_STORAGE_KEY_LANDING_SHOW_TIME = "landing_show_time";

export default function App() {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const deviceId = localStorage.getItem("deviceId")?.length
      ? localStorage.getItem("deviceId")
      : `${Math.floor(new Date().getTime() * 100 * Math.random() * 100)}`;
    localStorage.setItem("deviceId", deviceId);
    amplitude.getInstance().setDeviceId(deviceId);

    const identify = new amplitude.Identify();
    identify.set("browser_language", window.navigator.language);

    if (location.pathname === "/") {
      const userLanguage = navigator.language || navigator.userLanguage;
      identify.set("landing_locale", dic[userLanguage]);
      i18n.changeLanguage(userLanguage);
      history.replace(
        `/home/${dic[userLanguage] || "ru"}-pay${location.search}`
      );
    } else {
      try {
        const lang =
          dic[location.pathname?.split("/")[2].split("-")[0]] || "ru";
        i18n.changeLanguage(lang);
        identify.set("landing_locale", lang);
      } catch (error) {
        const userLanguage = navigator.language || navigator.userLanguage;
        i18n.changeLanguage(userLanguage);
        identify.set("landing_locale", dic[userLanguage]);

        history.replace(
          `/home/${dic[userLanguage] || "ru"}-pay${location.search}`
        );
      }
    }
    const isRtl = rtlDetect();
    if (isRtl) {
      document.body.style.direction = "rtl";
    }

    amplitude.identify(identify);
    let appsFlyerCUID = localStorage.getItem("appsFlyerCUID");
    if (appsFlyerCUID) {
      // eslint-disable-next-line no-undef
      AF("pba", "setCustomerUserId", appsFlyerCUID);
    } else {
      fetch("https://engbright.com/app-portal/landing/getcuid/" + deviceId)
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          appsFlyerCUID = result.appsFlyerCUID;
          localStorage.setItem("appsFlyerCUID", appsFlyerCUID);
          // eslint-disable-next-line no-undef
          AF("pba", "setCustomerUserId", appsFlyerCUID);
        });
    }

    setLoading(false);

    return () => {
      localStorage.removeItem("waypoint");
    };
  }, []);

  if (isLoading) return <Fragment />;

  return (
    <Switch>
      <Route exact path={`/home/${dic.ru}-pay`}>
        <Landing />
      </Route>
      <Route exact path={`/oops/${dic.ru}-pay-s`}>
        <ErrorPay />
      </Route>
      <Route exact path={`/oops/${dic.ru}-pay-m`}>
        <ErrorPayDuringPay />
      </Route>
      <Route exact path={`/pricing/${dic.ru}-sprint-pay`}>
        <SelectedTariff
          price={30}
          discountedPrice={19.99}
          tariff="sprint"
          i18keyRateIndex={0}
        />
      </Route>
      <Route exact path={`/pricing/${dic.ru}-marafon-pay`}>
        <SelectedTariff i18keyRateIndex={1} price={39.99} tariff="marafon" />
      </Route>
      <Route exact path={`/order/${dic.ru}-s`}>
        <SuccessPay sprint />
      </Route>
      <Route exact path={`/order/${dic.ru}-m`}>
        <SuccessPay marafon />
      </Route>
      <Route exact path={`/terms-of-use/${dic.ru}`}>
        <Terms />
      </Route>
      <Route exact path={`/privacy-policy/${dic.ru}`}>
        <Policy />
      </Route>

      <Route exact path={`/home/${dic.es}-pay`}>
        <Landing />
      </Route>
      <Route exact path={`/oops/${dic.es}-pay-s`}>
        <ErrorPay />
      </Route>
      <Route exact path={`/oops/${dic.es}-pay-m`}>
        <ErrorPayDuringPay />
      </Route>
      <Route exact path={`/pricing/${dic.es}-sprint-pay`}>
        <SelectedTariff
          price={39}
          discountedPrice={29.99}
          tariff="sprint"
          i18keyRateIndex={0}
        />
      </Route>
      <Route exact path={`/pricing/${dic.es}-marafon-pay`}>
        <SelectedTariff i18keyRateIndex={1} price={89.99} tariff="marafon" />
      </Route>
      <Route exact path={`/order/${dic.es}-s`}>
        <SuccessPay sprint price="$29.99" />
      </Route>
      <Route exact path={`/order/${dic.es}-m`}>
        <SuccessPay marafon price="$89.99" />
      </Route>
      <Route exact path={`/terms-of-use/${dic.es}`}>
        <Terms />
      </Route>
      <Route exact path={`/privacy-policy/${dic.es}`}>
        <Policy />
      </Route>

      <Route exact path={`/home/${dic.ar}-pay`}>
        <Landing />
      </Route>
      <Route exact path={`/oops/${dic.ar}-pay-s`}>
        <ErrorPay />
      </Route>
      <Route exact path={`/oops/${dic.ar}-pay-m`}>
        <ErrorPayDuringPay />
      </Route>
      <Route exact path={`/pricing/${dic.ar}-sprint-pay`}>
        <SelectedTariff
          price={30}
          discountedPrice={19.99}
          tariff="sprint"
          i18keyRateIndex={0}
        />
      </Route>
      <Route exact path={`/pricing/${dic.ar}-marafon-pay`}>
        <SelectedTariff i18keyRateIndex={1} price={39.99} tariff="marafon" />
      </Route>
      <Route exact path={`/order/${dic.ar}-s`}>
        <SuccessPay sprint />
      </Route>
      <Route exact path={`/order/${dic.ar}-m`}>
        <SuccessPay marafon />
      </Route>
      <Route exact path={`/terms-of-use/${dic.ar}`}>
        <Terms />
      </Route>
      <Route exact path={`/privacy-policy/${dic.ar}`}>
        <Policy />
      </Route>

      <Route exact path={`/home/${dic.zh}-pay`}>
        <Landing />
      </Route>
      <Route exact path={`/oops/${dic.zh}-pay-s`}>
        <ErrorPay />
      </Route>
      <Route exact path={`/oops/${dic.zh}-pay-m`}>
        <ErrorPayDuringPay />
      </Route>
      <Route exact path={`/pricing/${dic.zh}-sprint-pay`}>
        <SelectedTariff
          price={30}
          discountedPrice={19.99}
          tariff="sprint"
          i18keyRateIndex={0}
        />
      </Route>
      <Route exact path={`/pricing/${dic.zh}-marafon-pay`}>
        <SelectedTariff i18keyRateIndex={1} price={39.99} tariff="marafon" />
      </Route>
      <Route exact path={`/order/${dic.zh}-s`}>
        <SuccessPay sprint />
      </Route>
      <Route exact path={`/order/${dic.zh}-m`}>
        <SuccessPay marafon />
      </Route>
      <Route exact path={`/terms-of-use/${dic.zh}`}>
        <Terms />
      </Route>
      <Route exact path={`/privacy-policy/${dic.zh}`}>
        <Policy />
      </Route>

      <Route exact path={`/home/en-pay`}>
        <LandingEnglishVersion />
      </Route>
      <Route exact path={`/terms-of-use/en`}>
        <Terms />
      </Route>
      <Route exact path={`/privacy-policy/en`}>
        <Policy />
      </Route>

      <Route path="*">
        <center>
          <h1>500 Internal Server Error</h1>
        </center>
      </Route>
    </Switch>
  );
}
