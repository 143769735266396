import { Button } from "../Button/Button";
import { ReactComponent as GreenCheck } from "../../assets/greenCheck.svg";
import {
  RateStyled,
  RateDiscount,
  RateTitle,
  RateBenefitContainer,
  RateBenefit,
  RatePriceContainer,
  RatePrice,
  RatePriceDiscountedPrice,
} from "./style";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import i18n from "../../i18n";
import { dic } from "../../App";
import { Amplitude } from "@amplitude/react-amplitude";

export const isFacebookApp = () => {
  var ua = navigator.userAgent || navigator.vendor || window.opera;
  return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;
};

export const Rate = ({
  texts,
  price,
  discountedPrice,
  discount,
  name,
  isRtl,
    disabled
}) => {
  const { t } = useTranslation("landing");
  const history = useHistory();
  const language = dic[i18n.language.split("-")[0]] || "ru";

  return (
    <RateStyled>
      {discount && (
        <RateDiscount isRtl={isRtl}>
          {isRtl ? `-%${discount}` : `-${discount}%`}
        </RateDiscount>
      )}
      <RateTitle>
        {t("tariff")} «{texts.name}»
      </RateTitle>
      {texts.benefits.map((benefit) => (
        <RateBenefitContainer key={benefit.text}>
          <GreenCheck />
          <RateBenefit isRtl={isRtl}>
            <Trans>{benefit.text}</Trans>
          </RateBenefit>
        </RateBenefitContainer>
      ))}
      <RatePriceContainer>
        <RatePrice isDiscount={Boolean(discountedPrice)}>${price}</RatePrice>
        {discountedPrice && (
          <RatePriceDiscountedPrice isRtl={isRtl}>
            ${discountedPrice}
          </RatePriceDiscountedPrice>
        )}
      </RatePriceContainer>
      <Amplitude>
        {({ logEvent }) => (
          <Button
            text={texts.button}
            onClick={() => {
                if (disabled) {
                    return;
                }
              logEvent("landing_tap_purchase", { product: name });
              if (isFacebookApp() && name === "marafon") {
                const redirectUrl = localStorage.getItem("BrightRedirectUrl");
                if (redirectUrl) {
                  window.location.href = redirectUrl;
                } else {
                  console.error(`redirectUrl is empty`);
                }
              } else {
                history.push(`/pricing/${language}-${name}-pay`);
              }
            }}
          />
        )}
      </Amplitude>
    </RateStyled>
  );
};
