import styled from "styled-components";

export const BenefitsList = styled.div`
  margin: 0 -8px;
`;

export const Benefit = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  :last-child {
    margin-bottom: 0;
  }
`;

export const BenefitIcon = styled.img``;

export const BenefitText = styled.div`
  ${({ isRtl }) => (isRtl ? "margin-right: 20px" : "margin-left: 20px")};
  line-height: 22px;
  white-space: pre-line;
`;
