import styled, { css } from "styled-components";

const generateStylesFromAlign = (align) => {
  if (align === "center") {
    return css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `;
  }

  return css`
    display: flex;
    flex-direction: column;
  `;
};

export const ContainerStyled = styled.div`
  overflow: hidden;
`;

export const MobileContainer = styled.div`
  max-width: 375px;
  width: 100%;
  padding: 0 24px;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  ${({ align }) => generateStylesFromAlign(align)}
`;
