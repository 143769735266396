import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Button } from "../Button";
import { ReactComponent as Question } from "../../assets/questionPay.svg";
import cvcInfo from "../../assets/cvcInfo.svg";
import {
  OneRowInput,
  CardNumberElementStyled,
  CardExpiryElementStyled,
  CardCvcElementStyled,
  InfoPay,
  Item,
  AboutCVC,
  FloatingQuestin,
} from "./style";

import {
  useStripe,
  useElements,
  CardNumberElement,
  PaymentRequestButtonElement,
  PaymentElement,
} from "@stripe/react-stripe-js";

import { EmailDescription } from "../Input/style";
import { useHistory } from "react-router-dom";
import i18n from "../../i18n";
import { Trans } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { dic } from "../../App";
import amplitude from "amplitude-js";
import { confirmSubscription } from "./utils/confirmSubscription";

const inputStyles = {
  style: {
    base: {
      fontSize: "18px",
      lineHeight: "25px",

      "::placeholder": {
        fontSize: "18px",
        lineHeight: "25px",
        color: "rgba(0, 0, 0, 0.2)",
      },
    },
    invalid: {
      color: "#e8573e",
    },
  },
};

export const CheckoutForm = ({
  tariff,
  price,
  user,
  nameOfTariff,
  isRtl,
  subscription,
  clientSecret,
  promoCode,
}) => {
  const { t } = useTranslation("landing");

  const [hasCardNumber, setHasCardNumber] = useState(false);
  const [hasExpiry, setHasExpiry] = useState(false);
  const [hasCVV, setHasCVV] = useState(false);
  const [completeCardNumber, setCompleteCardNumber] = useState(false);
  const [completeExpiry, setCompleteExpiry] = useState(false);
  const [completeCVV, setCompleteCVV] = useState(false);
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const language = dic[i18n.language.split("-")[0]] || "ru";

  const redirectUrl =
    tariff === "marafon" ? `/order/${language}-m` : `/order/${language}-s`;
  const errorUrl =
    tariff === "marafon"
      ? `/oops/${language}-pay-m`
      : `/oops/${language}-pay-s`;

  useEffect(() => {
    const isSprint = tariff === "sprint";
    if (stripe) {
      const paymentRequestData = {
        country: "US",
        currency: "usd",
        total: {
          label: nameOfTariff,
          amount: isSprint ? 0 : Number(String(price).replace(".", "")),
        },
      };

      if (isSprint) {
        paymentRequestData.applePay = {
          recurringPaymentRequest: {
            regularBilling: {
              label: t("subscriptionApplePayDescription", {
                price,
              }),
              amount: Number(String(price).replace(".", "")),
              recurringPaymentIntervalUnit: "month",
              recurringPaymentIntervalCount: 2,
              recurringPaymentStartDate: new Date(
                subscription?.trial_end * 1000
              ),
            },
            paymentDescription: t("subscriptionApplePayDescription", {
              price,
            }),
            managementURL: window.origin,
          },
        };
      }

      const pr = stripe.paymentRequest(paymentRequestData);

      pr.on("paymentmethod", function (ev) {
        if (isSprint) {
          stripe
            .confirmSetup({
              confirmParams: {
                return_url: `${window.origin}${redirectUrl}?date=${subscription?.trial_end}`,
                payment_method: ev.paymentMethod.id,
              },
              clientSecret,
              redirect: "if_required",
            })
            .then(async (confirmResult) => {
              if (confirmResult.error) {
                const eventProps = {
                  how: "applepay",
                  product: tariff,
                  error_description: confirmResult.error,
                };
                amplitude
                  .getInstance()
                  .logEvent("landing_purchase_fail", eventProps);
                ev.complete("fail");
                return;
              } else {
                amplitude
                  .getInstance()
                  .logEvent("landing_purchase_success", { product: tariff });
                window?.fbq?.("track", "Purchase", { value: price });
                history.push(redirectUrl, {
                  date: new Date(
                    subscription.trial_start * 1000
                  ).toLocaleDateString(language),
                  promoCode,
                  subscriptionId: subscription.id,
                });
                ev.complete("success");
              }
            });
        }
        if (tariff === "marafon") {
          stripe
            .confirmCardPayment(
              user.clientSecret,
              { payment_method: ev.paymentMethod.id },
              { handleActions: false }
            )
            .then(async (confirmResult) => {
              if (confirmResult.error) {
                const eventProps = {
                  how: "applepay",
                  product: tariff,
                  error_description: confirmResult.error,
                };
                amplitude
                  .getInstance()
                  .logEvent("landing_purchase_fail", eventProps);
                ev.complete("fail");
              } else {
                ev.complete("success");
                if (confirmResult.paymentIntent.status === "requires_action") {
                  stripe
                    .confirmCardPayment(user.clientSecret)
                    .then(async (result) => {
                      if (result.error) {
                        const eventProps = {
                          how: "applepay",
                          product: tariff,
                          error_description: result.error,
                        };
                        amplitude
                          .getInstance()
                          .logEvent("landing_purchase_fail", eventProps);
                        history.push(errorUrl);
                      } else {
                        const confirmed = await confirmSubscription(
                          user.subscriptionId,
                          "applepay"
                        );
                        if (confirmed?.startDate) {
                          amplitude
                            .getInstance()
                            .logEvent("landing_purchase_success", {
                              product: tariff,
                            });
                          window?.fbq?.("track", "Purchase", { value: price });
                          history.push(redirectUrl, confirmed);
                        }
                      }
                    });
                } else {
                  const confirmed = await confirmSubscription(
                    user.subscriptionId,
                    "applepay"
                  );
                  if (confirmed?.startDate) {
                    amplitude
                      .getInstance()
                      .logEvent("landing_purchase_success", {
                        product: tariff,
                      });
                    history.push(redirectUrl, confirmed);
                  }
                }
              }
            });
        }
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [nameOfTariff, price, stripe, tariff, user.clientSecret]);

  useEffect(() => {
    amplitude.getInstance().logEvent("landing_cart_request_show");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !stripe ||
      !elements ||
      !completeCVV ||
      !completeExpiry ||
      !completeCardNumber
    ) {
      return;
    }
    setPaymentLoading(true);
    setLoading(true);
    amplitude.getInstance().logEvent("landing_start_purchase", {
      cvv: completeCVV,
      expire: completeExpiry,
      number: completeCardNumber,
      how: "card",
      product: tariff,
    });
    const cardNumber = elements.getElement(CardNumberElement);
    if (tariff === "sprint") {
      try {
        const result = await stripe.createPaymentMethod({
          type: "card",
          card: cardNumber,
        });
        if (result.error) {
          setPaymentLoading(false);
          setLoading(false);
          console.error(result);
          return result;
        }
        amplitude
          .getInstance()
          .logEvent("landing_purchase_success", { product: tariff });
        window?.fbq?.("track", "Purchase", { value: price });
        history.push(redirectUrl, {
          date: new Date(subscription.trial_start * 1000).toLocaleDateString(
            language
          ),
          promoCode,
          subscriptionId: subscription.id,
        });
      } catch (error) {
        const eventProps = {
          how: "card",
          product: tariff,
          error_description: error,
        };
        amplitude.getInstance().logEvent("landing_purchase_fail", eventProps);
        console.error(error);
        history.push(error);
      }
    }
    if (tariff === "marafon") {
      try {
        const data = await stripe.confirmCardPayment(user.clientSecret, {
          payment_method: {
            card: cardNumber,
          },
        });
        if (data.error?.code === "incorrect_number") {
          setPaymentLoading(false);
          setLoading(false);
          history.push(errorUrl);
          return;
        }
        if (data?.paymentIntent?.status === "succeeded") {
          const confirmed = await confirmSubscription(user.subscriptionId);
          if (confirmed?.startDate) {
            amplitude
              .getInstance()
              .logEvent("landing_purchase_success", { product: tariff });
            window?.fbq?.("track", "Purchase", { value: price });
            history.push(redirectUrl, confirmed);
          }
          return;
        }
      } catch (error) {
        const eventProps = {
          how: "card",
          product: tariff,
          error_description: error,
        };
        amplitude.getInstance().logEvent("landing_purchase_fail", eventProps);
        console.error(error);
        history.push(errorUrl);
      }
    }
    setPaymentLoading(false);
    setLoading(false);
  };

  if (!user) {
    return <div>loading...</div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      {paymentRequest && (
        <>
          <PaymentRequestButtonElement
            options={{
              paymentRequest,
              style: { paymentRequestButton: { height: "52px" } },
            }}
          />
          <div style={{ margin: "12px 0", textAlign: "center" }}>
            {t("payMomentum")}
          </div>
        </>
      )}
      <EmailDescription>{t("numberCard")}</EmailDescription>
      <CardNumberElementStyled
        onChange={(e) => {
          setHasCardNumber(e.empty);
          setCompleteCardNumber(e.complete);
        }}
        id="cardNumber"
        options={inputStyles}
      />
      <OneRowInput>
        <div style={{ width: "100%" }}>
          <EmailDescription>{t("expiredAt")}</EmailDescription>
          <CardExpiryElementStyled
            onChange={(e) => {
              setHasExpiry(e.empty);
              setCompleteExpiry(e.complete);
            }}
            id="cardExpiry"
            options={{ ...inputStyles }}
          />
        </div>
        <div style={{ width: "100%" }}>
          <EmailDescription>{t("cvv")}</EmailDescription>
          <AboutCVC>
            <CardCvcElementStyled
              id="cardCvc"
              onChange={(e) => {
                setHasCVV(e.empty);
                setCompleteCVV(e.complete);
              }}
              options={{ ...inputStyles, placeholder: "CVV" }}
            />
            <FloatingQuestin
              isRtl={isRtl}
              data-offset="{'bottom': '65px'}"
              data-class="floatinDetails"
              data-tip={`<div>
              <div class="whatIsCvv">${t("whatIsCvv")}</div>
              <div class="cvvIs">${t("cvvIs")}</div>
              <img height="74px" src="${cvcInfo}"/>
            </div>`}
            >
              <ReactTooltip
                effect="solid"
                place={isRtl ? "right" : "left"}
                html={true}
                arrowColor="#fff"
                backgroundColor="#fff"
                textColor="#000"
              />
              <Question
                isRtl={isRtl}
                style={{ transform: isRtl ? "scaleX(-1)" : "scaleX(1)" }}
              />
            </FloatingQuestin>
          </AboutCVC>
        </div>
      </OneRowInput>
      {tariff === "sprint" && (
        <InfoPay isRtl={isRtl}>
          <Item isRtl={isRtl}>
            <Trans>{t("todayNotPay")}</Trans>
          </Item>
          <Item isRtl={isRtl}>{t("youHave5Days")}</Item>
          <Item isRtl={isRtl}>{t("forConfirm")}</Item>
        </InfoPay>
      )}

      <Button
        renderLike="button"
        type="submit"
        text={tariff === "sprint" ? t("confirm") : t("payCard", { price })}
        disabled={isPaymentLoading || isLoading}
        styles={{
          width: "100%",
          maxWidth: "initial",
          marginTop: 32,
          background: "#27B535",
          boxShadow: "0px 4px 0px #209B2C",
        }}
      />
    </form>
  );
};
