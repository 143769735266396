import styled from "styled-components";

export const AnswerQuestionStyled = styled.div`
  padding: 12px 16px 16px;
  background: #f8a500;
  border-radius: 8px;
  margin-bottom: 8px;
  margin-left: -8px;
  margin-right: -8px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleText = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  padding-right: 10px;
`;

export const TitlePlus = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 8px;
  flex-shrink: 0;

  ::after,
  ::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #fff;
    border-radius: 3px;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-out;
  }

  ::after {
    height: 4px;
    width: 20px;
  }

  ::before {
    height: 20px;
    width: 4px;
    ${({ isOpen }) =>
      isOpen
        ? "transform: translate(-50%, -50%) rotate(90deg)"
        : "transform: translate(-50%, -50%)"};
  }
`;

export const Answer = styled.div`
  padding-top: 12px;
  line-height: 22px;
  color: #ffffff;

  a {
    color: #fff;
    font-weight: 700;
    text-decoration: underline;
  }
`;
