import styled from "styled-components";

export const SliderIphone = styled.img`
  margin: 0 auto;
  width: 136%;
  margin-bottom: -50px;
  transform: ${({ isRtl }) => (isRtl ? "scaleX(-1)" : "scaleX(1)")};
`;

export const SliderWrapper = styled.div`
  width: 200px;
  margin: 0px auto;
  position: relative;
  height: 436px;
`;

export const SliderIphoneWrapper = styled.div`
  position: absolute;
  top: -18px;
  left: -19px;
  right: -19px;
  bottom: 0;
`;

export const SliderContainer = styled.div``;

export const SliderImg = styled.img`
  width: 100%;
`;

export const SliderTitle = styled.div`
  line-height: 22px;
  text-align: center;
  color: #262626;
  font-weight: 800;
  margin-top: 40px;
`;

export const SliderText = styled.div`
  line-height: 22px;
  text-align: center;
  color: #262626;
  opacity: 0.9;
  margin-top: 8px;
`;

export const Controls = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
  align-items: center;
`;

export const SlideButton = styled.div`
  background: #f5f5f5;
  border-radius: 8px;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  cursor: pointer;

  transition: background 0.2s ease-out;

  &:hover {
    background: #d4d4d4;
  }
`;

export const PrevSlideButton = styled(SlideButton)`
  transform: ${({ isRtl }) => (isRtl ? "scaleX(-1)" : "scaleX(1)")};
`;

export const NextSlideButton = styled(SlideButton)`
  > img {
    transform: ${({ isRtl }) => (isRtl ? "scaleX(-1)" : "scaleX(1)")};
  }
`;

export const Dots = styled.div`
  display: flex;
  margin: 0 40px;
  justify-content: space-between;
  width: 100%;
`;

export const Dot = styled.div`
  background: ${({ isActive }) => (isActive ? "#FFB612" : "#e0e0e0")};
  border-radius: 25px;
  width: 8px;
  height: 8px;
`;
