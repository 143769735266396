import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import styled from "styled-components";

export const PaymentFormStyled = styled.div`
  margin-top: 38px;
`;

export const OneRowInput = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 0;

  > div {
    max-width: 156px;
  }
`;

export const CardNumberElementStyled = styled(CardNumberElement)`
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  width: 100%;
`;

export const CardExpiryElementStyled = styled(CardExpiryElement)`
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  width: 100%;
`;

export const CardCvcElementStyled = styled(CardCvcElement)`
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  width: 100%;
`;

export const PaymentRequestButtonElementStyled = styled(
  PaymentRequestButtonElement
)`
  border-radius: 8px;
  line-height: 66px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 800;
  border: none;
  outline: none;
  text-decoration: none;
  width: 100%;
  margin-top: 32;
  background: #27b535;
  box-shadow: 0px 4px 0px #209b2c;

  transition: all 0.2s ease-out;

  :active {
    background-color: #d98f00;
    box-shadow: 0 0 0 0 #d98f00;
  }
`;

export const InfoPay = styled.ul`
  list-style: none;
  padding: 0;
  ${({ isRtl }) => (isRtl ? "padding-right: 15px" : "padding-left: 15px")};
  margin-top: 24px;
`;

export const Item = styled.li`
  ::before {
    content: "\\2022";
    color: #28b535;
    font-weight: bold;
    display: inline-block;
    width: 15px;
    ${({ isRtl }) => (isRtl ? "margin-right: -15px" : "margin-left: -15px")};
  }

  margin-bottom: 16px;

  font-size: 14px;
  > * {
    font-size: 14px;
  }

  :last-child {
    margin-bottom: 0;
  }
`;

export const AboutCVC = styled.div`
  position: relative;

  & .floatinDetails.show {
    opacity: 1;
    filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.25));
    border-radius: 8px;
    padding: 16px;
    width: 152px;
    box-sizing: border-box;
  }

  .whatIsCvv {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .cvvIs {
    color: #7f7f84;
    font-size: 12px;
    margin-bottom: 16px;
    line-height: 16px;
  }

  .floatinDetails.place-left::after {
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    right: -10px;
    top: 17px;
    margin-top: 0;
    border-left-width: 9px;
  }

  .floatinDetails.place-right::after {
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    left: -7px;
    top: 17px;
    margin-top: 0;
    border-left-width: 9px;
  }
`;

export const FloatingQuestin = styled.div`
  position: absolute;
  ${({ isRtl }) => (isRtl ? "left: 0px" : "right: 0px")};
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
